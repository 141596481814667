<style lang="scss" scoped>

p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
ul {
  font-size: 18px !important;
}
ul li {
  list-style: point;
}

.a {
    color: rgb(24, 174, 194) !important;
}

</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Документы можно подать</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">
        <ul>
            <li>лично ПО АДРЕСУ: г. Северск, ул. Крупской, 17, ауд. 110</li>
            <li>через операторов почтовой связи 636036 г. Северск, ул. Крупской, 17, ауд. 104</li>
            <li>посредством электронной почты E-mail: <a class="a" href="mailto:postupi@spospk.ru">postupi@spospk.ru</a></li>
            <li>с использованием функционала ЕПГУ <a class="a" href="https://www.gosuslugi.ru">https://www.gosuslugi.ru</a></li>
        </ul>
        <p><b>«Северский промышленный колледж»</b> общежития не имеет и не обеспечивает поступающих жилыми помещениями.</p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          text: 'Документы можно подать',
          actrive: true,
        },
      ],
    }
  },
}
</script>