<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 25px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="Mounting">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Монтаж, техническое обслуживание и ремонт промышленного оборудования (по отраслям)
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>техник-механик</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Техник-механик  -  специалист, свободно ориентирующийся в современной технике и технологии. 
              Это незаменимый работник, который отвечает за работоспособность практически любого 
              технологического оборудования.
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/montaj.jpg" alt="Информационные системы">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 3 года 10 месяцев (на базе 9 классов). Форма обучения очная.
          </p>
        </b-col>
      </b-row>

      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Базовые знания физики и математики; <br>
                    Математическое  и логическое мышление; <br>
                    Пространственно-образное мышление; <br>
                    Расчетно-вычислительные навыки; <br>
                    Базовые навыки работы с чертежами и схемами; <br>
                    Базовые технические навыки; <br>
                    Навыки простых экспериментальных исследований.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Материаловедение. Метрология, стандартизация и сертификация. Монтаж промышленного 
                      оборудования и пусконаладочные работы.Техническое обслуживание и ремонт промышленного 
                      оборудования. Организация ремонтных, монтажных и наладочных работ по промышленному оборудованию. 
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Механик – это исполнитель, который следит за исправностью техники, монтирует ее и при 
                      необходимости восстанавливает. Основные обязанности специалиста: диагностика 
                      оборудования;  монтаж и ремонт промышленного оборудования и пусконаладочные работы, 
                      проведение регламентных работ по техническому обслуживанию  оборудования.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: физическая выносливость, отличное зрение и прекрасный глазомер, 
                      высокая координация, точность движений, слуховая чувствительность, хорошая память, 
                      выдержка и ответственность. <br>
                      Личные качества: высокая ответственность и дисциплина; хорошая память; стрессоустойчивость.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Осуществление работ работ по монтажу и ремонту промышленного оборудования, 
                      пусконаладочных работ и испытаний промышленного оборудования; <br>
                      изготавление и восстановление деталей; <br>
                      составление документации для проведения работ по эксплуатации промышленного оборудования. <br>
                      Планирование работы производственного подразделения. Проведение мероприятий по 
                      охране труда, технике безопасности и Бережливому производству.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <!-- <SliderStudents /> -->

      <b-col cols="12" class="mt-3">
        <p>          
          <a class="ist" href="https://disk.yandex.ru/i/bqEa1wsPUCJe_A" rel="noopener noreferrer"
            >Видео о профессии</a
          >
        </p>
      </b-col>
      <br>
    </b-container>
  </div>
</template>

<script>
import SliderStudents from "../../src/components/SliderStudents";

export default {
  name: "Mounting",
  components: {
    SliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Монтаж, техническое обслуживание и ремонт промышленного оборудования (по отраслям)',
            active: true
          }
        ]
      }
    }
};
</script>