<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
.info {
  font-weight: 600;
}
.text {
  text-decoration: underline;
}
</style>
<template>
  <div class="row d-flex justify-content-center m-0">
    <div class="col-lg-3 col-md-12 mb-4 mb-lg-0 mt-4">
      <img
        src="../assets/img/galary/xEnBJJ9bXG0.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />

      <img
        src="../assets/img/galary/FUpJKZ8aJSo.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />

      <img
        src="../assets/img/galary/XbHHppwIwhM.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />
    </div>

    <div class="col-lg-4 mb-4 mb-lg-0 mt-4">
      <img
        src="../assets/img/galary/ISc7Hl9lKgQ.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />

      <img
        src="../assets/img/galary/eGx0zHe3HQY.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />

      <img
        src="../assets/img/galary/J5vo0JrT-D0.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />
    </div>

    <div class="col-lg-4 mb-4 mb-lg-0 mt-4">
      <img
        src="../assets/img/galary/fNwEyAyHBSE.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />

      <img
        src="../assets/img/galary/R1Nis5euxRo.jpg"
        class="w-100 shadow-1-strong rounded mb-4"
      />
    </div>
  </div>
</template>


<script>
import Map from '../components/Map'
export default {
  components: {
    Map,
  },
  data() {
    return {
      slide: 0,
      sliding: null,
      items: [
        {
          text: 'Фотогалерея',
          href: '/Galary',
        },
      ],
      methods: {
        onSlideStart(slide) {
          this.sliding = true
        },
        onSlideEnd(slide) {
          this.sliding = false
        },
      },
    }
  },
}
</script>