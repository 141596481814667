<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 23px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="Portnoy">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Мастер по изготовлению швейных изделий
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>Мастер по изготовлению швейных изделий</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Мастер по изготовлению швейных изделий – творческая профессия, которая востребована на рынке труда. Люди, стремясь красиво, практично и модно одеваться, всегда будут пользоваться услугами специалистов данной области.  
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/portnoy.jpg" alt="Портной">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 1 года 10 месяцев на базе 9 классов. Форма обучения очная.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Усидчивость; <br>
                    Концентрированность внимания; <br>
                    Художественный вкус; <br>
                    Хорошее зрение; <br>
                    Координация движений рук; <br>
                    Пространственное воображение.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Основы конструирования одежды. Основы моделирования одежды. Основы художественного проектирования одежды. 
                      Технология пошива швейных изделий по индивидуальным заказам. Технология ремонта и обновления швейных изделий.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Снимает мерки. Подборка ткани в соответствии с назначением либо пожеланием клиента.
                      Раскраивает ткань. Нарезка и соединение воедино определенных компонентов из ткани.
                      Дорабатка вещи в процессе изготовления.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: портной должен обладать ярко выраженными творческими способностями, выносить монотонность и кропотливость процесса изготовления одежды. <br>
                      Личные качества: безупречное чувство стиля, терпеливость, ответственность, коммуникабельность, стрессоустойчивость, способность к черчению и рисованию, объемный глазомер, яркое воображение, креативность, усидчивость, аккуратность.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Портные изготавливают изделия различного назначения. Шьют верхнюю и легкую одежду, трикотажные изделия, белье, женские и мужские сумки, головные уборы, обувь, изделия из кожи и меха. Портные работают на предприятиях швейной промышленности, в ателье, салонах, в небольших частных фирмах, занимаются оказанием услуг по пошиву и ремонту одежды на дому. Повысив квалификацию, портной может стать закройщиком, модельером, мастером производственного обучения, технологом швейного производства. Хороший портной может заниматься педагогической деятельностью. Свои знания он может передавать в учебных заведениях или на специальных курсах. Часть успешных портных открывают собственное ателье, выполняя частные заказы.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <!-- <SliderStudents /> -->
    </b-container>
  </div>
</template>

<script>
import SliderStudents from "../../src/components/SliderStudents";

export default {
  name: "Electric",
  components: {
    SliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Мастер по изготовлению швейных изделий',
            active: true
          }
        ]
      }
    }
};
</script>