<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 17px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="School">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>Юриспруденция</h1>
        </b-col>
        <b-col cols="12">
          <h3>Квалификация: <em>юрист</em></h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Профессия юриста актуальна и востребована сегодня. Квалифицированный специалист разбирается в сути не
              только правовых, но и политических, экономических, финансовых процессов. Знания в области
              государственного, гражданского, трудового, хозяйственного, уголовного права, арбитража, уголовных и
              гражданских процессов, дает ему массу преимуществ. Он способен разрешать острейшие ситуации в правовом
              регулировании хозяйственной деятельности предприятий, предпринимательской деятельности, в сфере частного
              права. А также он может разрабатывать необходимую документацию, в частности учредительные документы,
              договора любого вида, вести деловую переписку.
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/pravo.jpg" alt="Информационные системы" />
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 2 года 10 месяцев на базе 9 классов, 2 год 6 месяцев на базе 11 классов. Форма обучения:
            очная, заочная
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple" />
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Базовые знания обществознания; <br />
                      правовое самосознание, толерантность; <br />
                      убежденности в необходимости защищать правопорядок правовыми способами и средствами; <br />
                      логическое мышление; <br />
                      коммуникативные способности; <br />
                      эмоциональная устойчивость; <br />
                      гуманность и доброжелательность, тактичность, выдержка и самообладание, наблюдательность,
                      инициативность; <br />
                      ответственность; <br />
                      эрудиция. <br />
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="" />
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Конституционное право. Административное право. Основы экологического права. Трудовое право.
                      Гражданское право. Семейное право. Гражданский процесс. Страховое дело. Статистика. Финансовое
                      право. Право социального обеспечения. Психология социально-правовой деятельности. Организация
                      работы органов и учреждений социальной защиты населения, органов Пенсионного фонда РФ
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom" />
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Юристы со средним профессиональным образованием работают в комитетах по соцзащите, центрах
                      соцобслуживания, пенсионных фондах, паспортных столах и отделах кадров, могут быть помощниками
                      нотариуса или адвоката.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man" />
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: внимательность к деталям, готовность к рутинной работе, работа с
                      людьми, навык поиска информации и ее переработка, аналитические и логические навыки, навыки
                      определения фактов и обстоятельств дела, умение использовать интернет-серфинг, владение навыками
                      многозадачности. <br />
                      Личные качества: обладание этическими нормами; широкий кругозор; внимательность; отличная память;
                      доброжелательность и сочувствие; чувство такта; самообладание и саморегуляция; инициативность;
                      развитые коммуникативные способности.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="" />
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Правоприменительная и правоохранительная деятельность в качестве юриста на должностях, не
                      предусматривающих наличие обязательного высшего юридического образования: юрисконсульта,
                      специалиста паспортного стола, младшего следователя, дознавателя, специалиста отдела кадров,
                      помощника нотариуса, адвоката и др. в органах государственной власти и местного самоуправления, а
                      также в различных организациях (предприятиях) независимо от их организационно-правовых форм.
                      Профессиональная деятельность, направленная на реализацию правовых норм в различных сферах и
                      органах социальной защиты населения, в органах Пенсионного фонда России, негосударственных
                      пенсионных фондах в качестве юриста, работа с социально незащищенными слоями населения
                      (пенсионеры, дети-сироты, инвалиды и др.)
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <LawSliderStudents />
    </b-container>
  </div>
</template>

<script>
import LawSliderStudents from '../../src/components/LawSliderStudents'

export default {
  name: 'Law',
  components: {
    LawSliderStudents,
  },
  data() {
    return {
      items: [
        {
          text: 'Специальности',
          href: '/Specialties',
        },
        {
          text: 'Право и организация социального обеспечения',
          active: true,
        },
      ],
    }
  },
}
</script>