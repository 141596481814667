<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 17px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="School">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Дошкольное образование
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>воспитатель детей дошкольного возраста</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Если вы любите детей, стремитесь помогать людям, хотите стабильную и интересную работу, то эта профессия
              для вас. Изменение в демографической ситуации привело к потребности в большем количестве персонала,
              который занимается воспитанием и гармоничным развитием маленьких детей. Профессионализм педагогов
              и искренняя забота о малышах способствуют не только их гармоничному развитию, но и формированию
              целостной личности ребенка, созданию благоприятных условий его развития. Настоящих воспитателей
              с благодарностью вспоминают на протяжении долгих лет как дети, так и их родители.
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/school.jpg" alt="Информационные системы">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 3 года 10 месяцев на базе 9 классов, 2 года 10 месяцев на базе 11 классов. <br>
            Форма обучения очная, заочная
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Коммуникабельность; <br>
                    эрудиция; <br>
                    грамотная устная и письменная речь; <br>
                    эмоциональный интеллект; <br>
                    уверенность в себе; <br>
                    дружелюбие; <br>
                    способность к сопережеванию и терпеливость; <br>
                    стрессоустойчивость; <br>
                    организованность; <br>
                    креативность; <br>
                    любознательность; <br>
                    эстетичный внешний вид
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Психолого-педагогические основы организации общения детей дошкольного возраста. 
                      Теоретические и методические основы организации игровой деятельности детей, 
                      организации трудовой деятельности дошкольников, физического воспитания и развития. 
                      Теория и методика математического развития, музыкального воспитания сразвития речи. 
                      Информационные технологии в профессиолнальной деятельности. Разработка и проведение 
                      занятий по робототехнике
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Воспитывают и обучают детей в дошкольных учереждениях и в домашних условиях.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: эмоциональная уравновешенность, развитые коммуникативные 
                      способности и эрудиция, высокая самоорганизация, непрерывное самосовершенствование и 
                      самообразование. <br>
                      Личные качества: наблюдательность, отзывчивость, внимательность, терпеливость, 
                      толерантность, трудолюбие, ответственность, дисциплинированность
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Воспитатель специализируется в воспитании детей дошкольного возраста. 
                      На него возложены функции по дошкольному обучению, организации детского досуга, 
                      прогулок, своевременного питания и сна.  Воспитатель знает методики, 
                      обеспечивающие умственное и физическое развитие детей, знаком с основами 
                      изобразительного творчества, математики, экологического образования, 
                      музыкального развития, владеет компьютерными технологиями.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <SchoolSliderStudents />
    </b-container>
  </div>
</template>

<script>
import SchoolSliderStudents from "../../src/components/SchoolSliderStudents";

export default {
  name: "School",
  components: {
    SchoolSliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Дошкольное образование',
            active: true
          }
        ]
      }
    }
};
</script>