<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
.pdf li,
.word li,
.link {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 30px;
}
li a {
  font-weight: bold;
  color: rgb(16, 30, 56) !important;
  font-size: 22px;
}
.pdf li::before {
  content: '';
  position: absolute;
  background: url('../assets/img/icons/pdf.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  left: 10px;
}
.word li::before {
  content: '';
  position: absolute;
  background: url('../assets/img/icons/word.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  left: 10px;
}
.excel li::before {
  content: '';
  position: absolute;
  background: url('../assets/img/icons/excel.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  left: 10px;
}
</style>






<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Рейтинг</h1>
      </b-col>
      <b-col cols="12">
        <ul class="word">
          <br />
          <li>
            <a target="_blank" :href="`${publicPath}./file/СПИСКИ рекомендованных к зачислению бюджет 2024.docx`">
              СПИСКИ рекомендованных к зачислению бюджет
            </a>
          </li>
          <li>
            <a target="_blank" :href="`${publicPath}./file/СПИСКИ рекомендованных к зачислению ВНЕбюджет 2024.docx`">
              СПИСКИ рекомендованных к зачислению внебюджет
            </a>
          </li>
        </ul>
        <!--<ul class="pdf">
          <li>
            <a target="_blank" :href="`${publicPath}./file/Результаты вступительных испытаний.pdf`">
             Результаты вступительных испытаний
            </a>
          </li>
          <br>
        </ul>-->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          text: 'Рейтинг',
          active: true,
        },
      ],
    }
  },
}
</script>