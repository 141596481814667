<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
a {
  color: rgb(16, 30, 56) !important;
  text-decoration: underline;
}

</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>
          Расписание на 1 сентября
        </h1>
      </b-col>
      <b-col cols="12">
        <b-table-simple small bordered responsive>
          <colgroup>
            <col span="1">
            <col span="1">
            <col span="1">
          </colgroup>
          <colgroup>
            <col span="1" style=" width: 100px">
          </colgroup>
          <b-tbody>            
            <b-tr>
              <b-th colspan="3">
                График проведения мероприятий, посвященных Дню знаний<br>(1 курс), 1 сентября 2021 года
              </b-th>
            </b-tr>            
            <b-tr>
              <b-td>Д071П</b-td>
              <b-td>8.30</b-td>
              <b-td>311</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д011П</b-td>
              <b-td>8.30</b-td>
              <b-td>303</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д021</b-td>
              <b-td>9.00</b-td>
              <b-td>203</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д041</b-td>
              <b-td>9.00</b-td>
              <b-td>204</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д191</b-td>
              <b-td>9.30</b-td>
              <b-td>216</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д191/1</b-td>
              <b-td>9.30</b-td>
              <b-td>305</b-td>              
            </b-tr>
            <b-tr>
              <b-td>ДН041</b-td>
              <b-td>10.00</b-td>
              <b-td>212/2</b-td>              
            </b-tr>
            <b-tr>
              <b-td>ДН021</b-td>
              <b-td>10.00</b-td>
              <b-td>211/2</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д191/2</b-td>
              <b-td>10.30</b-td>
              <b-td>211 (КЗ)</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д111</b-td>
              <b-td>10.30</b-td>
              <b-td>310</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д191П</b-td>
              <b-td>11.00</b-td>
              <b-td>210</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д111П</b-td>
              <b-td>11.00</b-td>
              <b-td>202</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д111/2П</b-td>
              <b-td>13.00</b-td>
              <b-td>316</b-td>              
            </b-tr>
            <b-tr>
              <b-td>Д011/2П</b-td>
              <b-td>13.00</b-td>
              <b-td>211 (КЗ)</b-td>              
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Rasp",
  data() {
      return {
        items: [
          {
            text: 'Расписание на 1 сентября',
            href: '/Rasp'
          }
        ]
      }
    }
}
</script>
