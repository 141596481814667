<style lang="scss" scoped>
//Основные стили
p {
  color: #000;
  text-align: justify;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.name {
  font-size: 25px;
  color: rgb(24, 174, 194);
  text-indent: 30px;
}
img {
  width: 200px;
}
//Адаптив
@media (max-width: 575.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .carousel-caption p {
    font-size: 15px;
  }
}
</style>
<template>
  <b-carousel
    id="carousel-1"
    :interval="10000"
    indicators
    img-width="1024"
    img-height="280"
    class="carusel-student mb-3 pb-5"
  >
    <b-carousel-slide img-blank img-alt="Blank image">
      <b-container>
        <b-row>
          <b-col cols="8" offset="3">
            <p class="name mb-0 mb-lg-2">Вячеслав, выпускник 2015 года.</p>
          </b-col>
          <b-col class="d-none d-lg-block" cols="3">
            <img class="img-fluid" src="../assets/img/others/anikin.png" alt="student" />
          </b-col>
          <b-col cols="12" lg="8">
            <p>
              Период обучения в СПК запомнился в первую очередь атмосферой. Так как я люблю готовить, мог в полной мере
              реализовать свою любовь к этому делу. Здесь достойный уровень подготовки и сильные преподаватели. Колледж
              подарил мне опыт работы в хороших заведениях, я стал более уверенно чувствовать себя в профессии. <br> В
              настоящее время – су-шеф в кафе «Гости».
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
export default {}
</script>
