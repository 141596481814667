<style lang="scss" scoped>
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
ul {
  font-size: 18px !important;
}
ul li {
  list-style: none;
}
ul li::before {
  content: '-';
  padding-right: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Специальности</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">
        <p>
          <b> В 2023 году производится набор на следующие специальности </b>
          (подробнее в разделе
          <a class="ist" href="/Specialties" rel="noopener noreferrer">«План приёма на 2023/2024 учебный год»</a>):
        </p>
        <ul>
          <li>
            <b-link to="InfoSysAndProg" class="ist">Информационные системы и программирование (специалист по информационным системам) (ТОП-50)</b-link>
          </li>
          <li>
            <b-link to="InfoSysAndWeb" class="ist">Информационные системы и программирование (разработчик веб и мультимедийных технологий) (ТОП-50)</b-link>
          </li>
          <li>
            <b-link to="Coock" class="ist">Поварское и кондитерское дело (ТОП-50)</b-link>
          </li>
          <li>
            <b-link to="Law" class="ist">Юриспруденция</b-link>
          </li>
          <li>
            <b-link to="School" class="ist">Дошкольное образование</b-link>
          </li>
          <li>
            <b-link to="Electric" class="ist">Тепловые электрические станции</b-link>
          </li>
          <li>
            <b-link to="Station" class="ist">Электрические станции, сети и системы</b-link>
          </li>
          <li>
            <b-link to="Mounting" class="ist">Монтаж, техническое обслуживание и ремонт промышленного оборудования  (по отраслям) (ТОП-50)</b-link>
          </li>
          <li>
            <b-link to="Locksmith" class="ist">Мастер слесарных работ (ТОП-50)</b-link>
          </li>
          <li>
            <b-link to="Electrician" class="ist">Электромонтер по ремонту и обслуживанию электрооборудования</b-link>
          </li>
          <li>
            <b-link to="Portnoy" class="ist">Мастер по изготовлению швейных изделий</b-link>
          </li>
          <li>
            <b-link to="Prepodavaniye" class="ist">Преподавание в начальных классах</b-link>
            </li>
        </ul>
        <p>
          <b>Прием на обучение осуществляется на общедоступной основе.</b>
        </p>
        <p>
          <b>Вступительные испытания</b> (творческое и психологическое) <b>проводятся по специальностям:</b>
        </p>
        <p>
          <b>44.02.01 Дошкольное образование;</b>
        </p>
        <p>
          <b>44.02.02 Преподавание в начальных классах.</b>
        </p>
        <p>
          Творческое испытание проводится в форме письменного эссе. Психологическое испытание проводится в форме тестирования и (или) собеседования
        </p>
        <p>
          <b>Вступительные испытания будут проходить с 10 по 14 августа включительно.</b>
        </p>
        <p>
          После проведения двух этапов рассчитывается совокупная оценка результатов вступительных испытаний как сумма оценок по каждому испытанию (максимум 20 баллов).
        </p>
        <p>
          <em>
            При равенстве средних баллов аттестатов у поступающих приоритет при зачислении отдается абитуриенту  
            имеющему результаты индивидуальных достижений и (или) наличие договора о целевом обучении.
          </em>
        </p>
        <ul>
          <li>
            <em>
              имеющим более высокий средний балл аттестата
            </em>
          </li>
          <p></p>
          <li>
            <em>
              имеющий  договор о целевом обучении
            </em>
          </li>
          <p></p>
          <li>
            <em>
              более значимые результаты личных достижений
            </em>
          </li>
          <p></p>
        </ul>
        <p></p>
        <p>
          <b>
            При приеме на обучение по образовательным программам 
            образовательной организацией учитываются следующие результаты индивидуальных достижений:
          </b>
        </p>
        <ol>
          <li>
            наличие статуса победителя и призера в олимпиадах и иных интеллектуальных и (или) творческих конкурсах, мероприятиях, 
            направленных на развитие интеллектуальных и творческих способностей, способностей к занятиям физической культурой и спортом, 
            интереса к научной (научно-исследовательской), инженерно-технической, изобретательской, творческой, физкультурно-спортивной деятельности, 
            а также на пропаганду научных знаний, творческих и спортивных достижений в соответствии с постановлением 
            Правительства Российской Федерации от 17 ноября 2015 г. N 1239 «Об утверждении Правил выявления детей, проявивших выдающиеся способности, 
            сопровождения и мониторинга их дальнейшего развития»;
          </li>
          <li>
            наличие у поступающего статуса победителя и призера чемпионата по профессиональному мастерству среди 
            инвалидов и лиц с ограниченными возможностями здоровья «Абилимпикс»;
          </li>
          <li>
            наличие у поступающего статуса победителя и призера чемпионата профессионального мастерства, проводимого автономной некоммерческой организацией 
            «Агентство развития профессионального мастерства (Ворлдскиллс Россия)», или международной организацией «Ворлдскиллс Интернешнл WorldSkills International», 
            или международной организацией «Ворлдскиллс Европа (WorldSkills Europe)».
          </li>
          <li>наличие у поступающего статуса чемпиона или призера Олимпийских игр, Паралимпийских игр и Сурдлимпийских игр, чемпиона мира, чемпиона Европы, 
            лица занявшего первое место на первенстве мира, первенстве Европе по видам спорта, включенным в программы Олимпийских игр, 
            Паралимпийских игр и Сурллимпийских игр. 
          </li>
          <li>наличие у поступающего статуса чемпиона мира, чемпиона Европы, лица, занявшего первое место на первенстве мира, первенстве Европе по видам спорта, 
            включенным в программы Олимпийских игр, Паралимпийских игр и Сурллимпийских игр.
          </li>
        </ol>
          <p></p>
          <p>
            <b>Преимущественное право зачисления на обучение при условии успешного прохождения вступительных 
              испытаний и при прочих равных условиях имеют лица,</b> указанные в части 7 статьи 71 Федерального закона  «Об образовании в Российской Федерации».
          </p>
          <p>
            <b>
              Справки по телефонам 8(3823) 780-327, 780-333, 780-366
            </b>
          </p>
          <p>
            пн. - чт. 9.00 - 17.00 <br> пт. 9.00 - 16.00
          </p>
          <p>
            ПРИЕМНАЯ КОМИССИЯ ОГБПОУ "СПК": <br> Адрес: г. Северск, ул. Крупской, 17, ауд. 112. <br>
            Телефон: +7(3823) 780-327; +780-333; 780-366 <br> E-mail: postupi@spospk.ru
          </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          text: 'Специальности',
          actrive: true,
        },
      ],
    }
  },
}
</script>