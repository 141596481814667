<style lang="scss" scoped>
.main {
  position: relative;
  max-width: 100%;
  height: 70vh;
  background: url(../assets/img/others/bcgr.jpg) center no-repeat;
  background-size: cover;
  background-attachment: fixed;
}
.first-bgr {
  position: absolute;
  background: #263042;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}
.carousel-item {
  max-height: 650px !important;
  z-index: 0;
}
p {
  margin-top: 150px;
  font-size: 30px;
}
.min-text p {
  font-size: 25px;
}
h1 {
  font-size: 39px;
}
</style>
<template>
  <div>
    <b-carousel
      id="carousel-1"
      :interval="4000"
      indicators
      background="#ababab"
      img-width="1024"
      img-height="480"
      class="carousel-header"
    >
      <b-link to="InfoSysAndProg">
        <b-carousel-slide class="slide-one" img-src="../assets/img/others/IS.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">
                  Информационные системы и программирование (специалист по информационным системам)
                </h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Специалист, без которого не может существовать современная организация. В его обязанности входит
                  поддержание в работоспособном состоянии любой ИТ-инфраструктуры в целом. Он владеет широким спектром
                  современных информационных технологий, навыками проектирования, программирования и сопровождения
                  систем.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <b-link to="InfoSysAndWeb">
        <b-carousel-slide class="min-text slide-two" img-src="../assets/img/others/web.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">
                  Информационные системы и программирование (разработчик веб и мультимедийных технологий)
                </h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Разработчик веб и мультимедийных приложений. Одна из самых актуальных профессий в отрасли! Ни одна
                  организация сейчас не обходится без веб-сайта и Интернет-приложения. Разработчик Web и мультимедийных
                  приложений – программист, который занимается созданием, изменением и сопровождением web-сайтов,
                  корпоративных порталов организаций, мультимедиа и интерактивных приложений, используя современные
                  языки программирования и средства интерактивной графики.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <b-link to="Coock">
        <b-carousel-slide class="min-text slide-three" img-src="../assets/img/others/coock.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Поварское и кондитерское дело</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Специалист по поварскому и кондитерскому делу – одна из наиболее востребованных профессий на рынке
                  сферы услуг. Это специалист по приготовлению пищи и мастер по изготовлению сладостей. В обязанности
                  специалиста входит приготовление блюда по специальным рецептам. Однако, он может изменять их по своему
                  вкусу, то есть подходить к процессу творчески. Специалист разрабатывает технологические карты блюд,
                  расширяет ассортимент кулинарных, кондитерских и других изделий, знает биологическую ценность,
                  качественные характеристики используемого сырья и продукции, знаком с технологией производства,
                  санитарными нормами, правилами составления меню, культурой обслуживания.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <b-link to="Law">
        <b-carousel-slide class="min-text slide-for" img-src="../assets/img/others/pravo.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Право и организация социального обеспечения</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Профессия юриста актуальна и востребована сегодня. Квалифицированный специалист разбирается в сути не
                  только правовых, но и политических, экономических, финансовых процессов. Знания в области
                  государственного, гражданского, трудового, хозяйственного, уголовного права, арбитража, уголовных и
                  гражданских процессов, дает ему массу преимуществ. Он способен разрешать острейшие ситуации в правовом
                  регулировании хозяйственной деятельности предприятий, предпринимательской деятельности, в сфере
                  частного права. А также он может разрабатывать необходимую документацию, в частности учредительные
                  документы, договора любого вида, вести деловую переписку.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <b-link to="School">
        <b-carousel-slide class="min-text slide-five" img-src="../assets/img/others/school.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Дошкольное образование</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Если вы любите детей, стремитесь помогать людям, хотите стабильную и интересную работу, то эта
                  профессия для вас. Изменение в демографической ситуации привело к потребности в большем количестве
                  персонала, который занимается воспитанием и гармоничным развитием маленьких детей. Профессионализм
                  педагогов и искренняя забота о малышах способствуют не только их гармоничному развитию, но и
                  формированию целостной личности ребенка, созданию благоприятных условий его развития. Настоящих
                  воспитателей с благодарностью вспоминают на протяжении долгих лет как дети, так и их родители.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <!--<b-link to="Electric">
        <b-carousel-slide class="slide-six" img-src="../assets/img/others/electric.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Тепловые электрические станции</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Профессия техник-теплотехник актуальна и востребована во всех отраслях экономики: от комунального
                  хозяйства до атомных электростанций. Без специалистов по обслуживанию и ремонту теплоэнергетического
                  оборудования не может функционировать ни одно крупное предприятие или организация. Техник-теплотехник
                  обладает знаниями и умениями в области обслуживания котельного и турбинного оборудования тепловых
                  электрических станций.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>-->
      <b-link to="Station">
        <b-carousel-slide class="slide-seven" img-src="../assets/img/others/station.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Электрические станции, сети и системы</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Техники-электрики - это профессионал в ремонте и эксплуатации электрического оборудования и сетей. В
                  их компетенции не только бытовые электроприборы и проводка, но и производственные мощности на
                  электростанциях и промышленных предприятиях.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <b-link to="Mounting">
        <b-carousel-slide class="slide-eight" img-src="../assets/img/others/montaj.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">
                  Монтаж, техническое обслуживание и ремонт промышленного оборудования (по отраслям)
                </h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Техник-механик - специалист, свободно ориентирующийся в современной технике и технологии. Это
                  незаменимый работник, который отвечает за работоспособность практически любого технологического
                  оборудования.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <b-link to="Locksmith">
        <b-carousel-slide class="slide-nine" img-src="../assets/img/others/locksmith.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Мастер слесарных работ</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Слесарь - квалифицированный рабочий, востребованный на любом производстве, где есть машины и
                  механизмы. Он знает, как произвести сборку и регулировку оборудования, приемы слесарной обработки и
                  может изготовить множество деталей . От качественной работы слесарей зависит бесперебойная работа
                  механизмов, сосредоточенных во всех отраслях экономики.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <!--<b-link to="Electrician">
        <b-carousel-slide class="slide-ten" img-src="../assets/img/others/electrician.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Электромонтер по ремонту и обслуживанию электрооборудования</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Электромонтер по ремонту и обслуживанию электрооборудования - рабочая профессия, востребованная как в
                  быту, так и в промышленности. Это вызвано необходимостью использования всевозможных электрических
                  устройств для обеспечения комфортного быта и эффективного выполнения рабочих задач.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>-->
      <b-link to="Portnoy">
        <b-carousel-slide class="slide-ten" img-src="../assets/img/others/portnoy.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Мастер по изготовлению швейных изделий</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Мастер по изготовлению швейных изделий – творческая профессия, которая востребована на рынке труда. Люди, стремясь красиво, практично 
                  и модно одеваться, всегда будут пользоваться услугами специалистов данной области.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
      <b-link to="Prepodavaniye">
        <b-carousel-slide class="slide-ten" img-src="../assets/img/others/prepodavaniye.jpg">
          <b-container class="first-bgr" fluid></b-container>
          <b-container>
            <b-row class="mt-5">
              <b-col cols="12">
                <h1 class="text-uppercase">Преподавание в начальных классах</h1>
              </b-col>
              <b-col cols="12">
                <p class="text-justify">
                  Уникальность профессии учителя начальных классов заключается в совмещении сразу нескольких социальных статусов. 
                  Он и учитель, и воспитатель, наставник и помощник, защитник и вдохновитель, который призван помочь ребёнку приобрести 
                  не только знания об окружающем мире, овладеть умениями и навыками, но помочь адаптироваться в этом мире, раскрыть и 
                  проявить свои способности, почувствовать себя самостоятельной творческой личностью.
                </p>
              </b-col>
            </b-row>
          </b-container>
        </b-carousel-slide>
      </b-link>
    </b-carousel>
  </div>
</template>

<script>
export default {}
</script>
