<style lang="scss" scoped>
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
ul {
  font-size: 18px !important;
}
ul li {
  list-style: none;
}
ul li::before {
  content: '-';
  padding-right: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Информация о приеме документов от лиц с ОВЗ и инвалидов</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">
        <p>
          В соответствии с п. 2.2 "Положения об условиях обучения инвалидов и лиц с ограниченными возможностями здоровья
          "ОГБПОУ "СПК" от 17.02.2016 г. (смотреть в разделе
          <a href="http://spospk.ru/ovz.html" target="_blank" rel="noopener noreferrer" class="ist">Документы</a>), лица
          с ОВЗ при поступлении подают стандартный набор документов и представляют по своему усмотрению оригинал или
          ксерокопию одного из следующих документов: заключение психолого-медико-педагогической комиссии; справку об
          установлении инвалидности, выданную федеральным государственным учреждением медико-социальной экспертизы.
        </p>
        <p>
          <b> Справки по телефонам 8(3823) 780-327, 780-333, 780-366 </b>
        </p>
        <p>
          пн. - чт. 9.00 - 17.00 <br />
          пт. 9.00 - 16.00
        </p>
        <p>
          ПРИЕМНАЯ КОМИССИЯ ОГБПОУ "СПК": <br />
          Адрес: г. Северск, ул. Крупской, 17, ауд. 112. <br />
          Телефон: +7(3823) 780-327; +780-333; 780-366 <br />
          E-mail: postupi@spospk.ru
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          text: 'Информация о приеме документов от лиц с ОВЗ и инвалидов',
          actrive: true,
        },
      ],
    }
  },
}
</script>