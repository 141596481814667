<style lang="scss" scoped>
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
ul {
  font-size: 18px !important;
}
ul li {
  list-style: none;
}
ul li::before {
  content: '-';
  padding-right: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <!--<h1>Предварительная информация о зачислении</h1>-->
        <h1>Информация о зачислении</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">       
        <ul>
          <li>
            <!--<a class="ist" download="Предварительные списки к зачислению (бюджет)"
              target="_blank" href="/file/tentativelist9b.docx">Предварительные списки к зачислению (бюджет)</a>-->
              <a class="ist" download="Приказ о зачислении (бюджет)"
              target="_blank" href="/file/prikazb.pdf">Приказ о зачислении (бюджет)</a>
          </li>
          <li>
            <!--<a class="ist" download="Приказ о зачислении (платно)"
              target="_blank" href="/file/tentativelist9p.docx">Предварительные списки к зачислению (платно)</a>-->
              <a class="ist" download="Приказ о зачислении (внебюджет)"
              target="_blank" href="/file/prikazp.pdf">Приказ о зачислении (внебюджет)</a>
          </li>                   
        </ul>        
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          //text: 'Предварительная информация о зачислении',
          text: 'Информация о зачислении',
          actrive: true,
        },
      ],
    }
  },
}
</script>