<style lang="scss" scoped>
h2 {
  color: #222222;
}
a p {
  font-size: 20px;
}
p.date {
  font-size: 14px;
}
.btn {
  background-color: turquoise;
  color: white;
  width: 200px;
  height: 50px;
  font-size: 20px;
}
.fade-enter-active {
  transition: opacity 0.5s;
}
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}

// .card-content {

//   clip-path: polygon(0 0, 100% 0, 100% 73%, 73% 100%, 0 100%, 0% 50%);
// }
.img {
  position: absolute;
  z-index: 1;
}

#news {
  padding-top: 200px;
  padding-bottom: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.cards {
  width: 350px;
  height: 450px;
  background: transparent;
  border: 1px solid white;
  border-radius: 0px;
}

.cards__item {
  height: 100%;
  width: 100%;
  border: 1px solid white;
  background: white;
  cursor: pointer;
  clip-path: polygon(0 0, 100% 0, 100% 82%, 100% 100%, 0 100%, 0 50%);
  transition: 1s ease all;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cardContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 90%;
  height: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.cardContainerTwo {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 10px;
  background: white;
}

.cardContainerItem {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  width: 340px;
  font-weight: bold;
  padding-bottom: 20px;
}

.cardContainerItem > a {
  text-decoration: none;
  margin-top: 20px;
  font-size: 20px;
  color: #18aec2 !important;
}

.cardContainerItem > img {
  border-bottom: 2px solid #18aec2;
  padding: 10px;
  width:340px;
  height:200px;
}

.a {
  color: #18aec2 !important;
  font-size: 20px;
  text-decoration: underline;
  font-size: 24px;
}

.text {
  font-size: 42px;
}

@media (max-width: 400px) {
  .text {
    font-size: 28px;
  }
  .cards {
    width: 310px;
  }
}
</style>

<template>
  <div id="news">
    <div class="cardContainer">
      <div class="cards">
        <div class="cards__item">
          <div class="wrapper">
            <h3 class="text">Выбрать специальность</h3>
          </div>
          <div style="margin-left: auto; display: flex; align-items: end; justify-content: space-between; width: 100%">
            <router-link to="/Specialties" class="a">Подробнее</router-link>
            <img class="img-fluid" src="../assets/img/newIcons/pick_spec.svg" width="100px" alt="logotype" />
          </div>
        </div>
      </div>
      <div class="cards">
        <div class="cards__item">
          <div class="wrapper">
            <h3 class="text">Сроки приёма документов</h3>
          </div>
          <div style="margin-left: auto; display: flex; align-items: end; justify-content: space-between; width: 100%">
            <router-link to="/InfoAboutDocDate" class="a">Подробнее</router-link>
            <img class="img-fluid" src="../assets/img/newIcons/sroki_piema.svg" width="100px" alt="logotype" />
          </div>
        </div>
      </div>
      <div class="cards">
        <div class="cards__item">
          <div class="wrapper">
            <h3 class="text">
              Подача документов
              <p style="font-size: 32px">для поступления</p>
            </h3>
          </div>
          <div style="margin-left: auto; display: flex; align-items: end; justify-content: space-between; width: 100%">
            <router-link to="/InfoAboutSubmit" class="a">Подробнее</router-link>
            <img class="img-fluid" src="../assets/img/newIcons/podacha_doc.svg" width="100px" alt="logotype" />
          </div>
        </div>
      </div>
      <div class="cards">
        <div class="cards__item">
          <div class="wrapper">
            <h3 class="text">Документы можно подать</h3>
          </div>
          <div style="margin-left: auto; display: flex; align-items: end; justify-content: space-between; width: 100%">
            <router-link to="/SubmitDocuments" class="a">Подробнее</router-link>
            <img class="img-fluid" src="../assets/img/newIcons/document.svg" width="100px" alt="logotype" />
          </div>
        </div>
      </div>
    </div>
    <h3 style="margin-top: 60px; margin-bottom: 60px; color: white">Предприятия-партнёры</h3>
    <div class="cardContainerTwo">
      <a href="https://shk.tvel.ru/" class="cardContainerItem">
        <a href="https://shk.tvel.ru/">АО "СХК"</a>
        <img src="../assets/img/allLogo/skhk.svg"/>
      </a>
      <a href="https://www.rusatom-utilities.ru/" class="cardContainerItem">
        <a href="https://www.rusatom-utilities.ru/">АО "РИР"</a>
        <img src="../assets/img/allLogo/rir.svg" />
      </a>
      <a href="https://www.priborservice.pro/" class="cardContainerItem">
        <a href="https://www.priborservice.pro/">ООО "ПРИБОР-СЕРВИС"</a>
        <img src="../assets/img/allLogo/pribor_servis.svg"  />
      </a>
      <a href="https://sibregionprom.ru/" class="cardContainerItem">
        <a href="https://sibregionprom.ru/">ООО "СИБРЕГИОН ПРОМСЕРВИС"</a>
        <img src="../assets/img/allLogo/sibregion.svg"  />
      </a>
      <a href="https://titan-group.ru/#slide2" class="cardContainerItem">
        <a href="https://titan-group.ru/#slide2">ООО "СИБИРСКИЙ ТИТАН"</a>
        <img src="../assets/img/allLogo/titan.svg"  />
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      name: 'Показать еще',
    }
  },
  methods: {
    change() {
      if (this.show == true) {
        this.name = 'Скрыть'
      } else {
        this.name = 'Показать еще'
      }
    },
  },
}
</script>
