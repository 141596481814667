<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 16px;
  font-weight: lighter;
  color: #fff;
  padding: 10px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="Coock">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Поварское и кондитерское дело
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>специалист по поварскому и кондитерскому делу</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Специалист по поварскому и кондитерскому делу – одна из наиболее востребованных профессий на 
              рынке сферы услуг. Это специалист по приготовлению пищи и мастер по изготовлению сладостей. 
              В обязанности специалиста входит приготовление блюда по специальным рецептам. Однако, он 
              может изменять их по своему вкусу, то есть подходить к процессу творчески. Специалист 
              разрабатывает технологические карты блюд, расширяет ассортимент кулинарных, кондитерских и 
              других изделий, знает биологическую ценность, качественные характеристики используемого сырья 
              и продукции, знаком с технологией производства, санитарными нормами, правилами составления меню, 
              культурой обслуживания. 
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/coock.jpg" alt="Информационные системы">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 3 года 10 месяцев на базе 9 классов. Форма обучения очная.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые знания и навыки для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Базовые знания химии; <br>
                    зрительная, обонятельная и вкусовая чувствительность; <br>
                    хорошо развитое внимание (такие его параметры как объем, распределение и переключение); <br>
                    склонность к анализу и прогнозу; <br>
                    наглядно-действенное мышление, образная память; <br>
                    творческие способности; <br>
                    эстетический вкус; <br>
                    аккуратность; <br>
                    требовательность; <br>
                    коммуникабельность; <br>
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Кухни мира. Микробиология, физиология питания, санитария и гигиена. Организация хранения и 
                      контроль запасов и сырья. Процессы приготовления, оформления и подготовки к реализации 
                      различных  блюд и полуфабикатов. Организация и контроль обслуживания и текущей деятельности
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Приготовливает широкий ассортимент простых и основных блюд, хлебобулочных и  кондитерских изделий.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества:  развитое обоняния, тактильная чувствительность, 
                      вкусовое восприятия; хорошее развитие моторики рук, хорошее зрение, хорошая 
                      память и высокий уровень распределения и переключения внимания;  организаторские 
                      и коммуникативные  навыки. <br>
                      Личные качества: внимательность; работа в команде; аккуратность; терпеливость; 
                      коммуникабельность; эстетический вкус.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Приготовление, творческое оформление  и подготовка к реализации полуфабрикатов различных 
                      кулинарных и колндитерских блюд сложного ассортимента. <br>
                      Осуществлять разработку и  адаптацию рецептов,  в том числе авторских, брендовых, региональных 
                      с учетом потребностей различных категорий потребителей, видов и форм обслуживания; <br>
                      Организовывать подготовку рабочих мест, оборудования, сырья, материалов для 
                      приготовления любого ассортимента продукции в соответствии с инструкциями и регламентами.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <CoockSliderStudents />
    </b-container>
  </div>
</template>

<script>
import CoockSliderStudents from "../../src/components/CoockSliderStudents";

export default {
  name: "Coock",
  components: {
    CoockSliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Поварское и кондитерское дело',
            active: true
          }
        ]
      }
    }
};
</script>