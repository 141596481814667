<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 23px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="Electric">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Тепловые электрические станции
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>техник-теплотехник</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Профессия техник-теплотехник актуальна и востребована  во всех отраслях экономики: от 
              комунального хозяйства до атомных электростанций.  Без специалистов по обслуживанию и 
              ремонту теплоэнергетического оборудования не может функционировать ни одно  крупное 
              предприятие или организация. Техник-теплотехник обладает знаниями и умениями в 
              области обслуживания котельного и турбинного оборудования тепловых электрических станций.  
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/electric.jpg" alt="Информационные системы">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 3 года 10 месяцев (на базе 9 классов). Форма обучения очная.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Базовые знания физики и математики; <br>
                    Математическое  и логическое мышление; <br>
                    Пространственно-образное мышление; <br>
                    Расчетно-вычислительные навыки; <br>
                    Базовые навыки работы с чертежами и схемами; <br>
                    Базовые технические навыки; <br>
                    Навыки простых экспериментальных исследований.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Инженерная графика. Материаловедение. Тепловые и атомные электростанции. 
                      Альтернативные источники энергии. Техническая механика. Техническое 
                      обслуживание котельного и турбинного оборудования на тепловых 
                      электрических станциях. Технология ремонта теплоэнергетического 
                      оборудования. Электротехника и электроника
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Эксплуатирует теплотехническое оборудование.  Обеспечивает энергосбережение, принимает 
                      участие в переоснащении производственных мощностей и ремонтных работах оборудования.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: гибкость мышления; наглядно-образное мышление; 
                      высокий уровень концентрации, распределения и устойчивости внимания; 
                      хорошая память; пространственное воображение; способность принимать и 
                      внедрять новое на практике; аналитические способности. <br>
                      Личные качества: рациональность; целеустремленность, инициативность, 
                      настойчивость; наблюдательность, самостоятельность; изобретательность,  
                      терпеливость, аккуратность, коммуникабельность; технический склад ума, 
                      внимательность, хорошая память.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Обслуживание и ремонт теплотехнического оборудования. <br>
                      Контроль и управление параметрами производства тепловой энергии. 
                      Планирование работы производственного подразделения. Проведение 
                      мероприятий по охране труда, технике безопасности и Бережливому производству.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <!-- <SliderStudents /> -->
    </b-container>
  </div>
</template>

<script>
import SliderStudents from "../../src/components/SliderStudents";

export default {
  name: "Electric",
  components: {
    SliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Тепловые электрические станции',
            active: true
          }
        ]
      }
    }
};
</script>