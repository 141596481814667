<style scoped>
.Home {
  background: url('../assets/img/theme/bg.png');
  object-fit: contain;
  background-attachment: fixed;
}
</style>
<template>
  <div class="Home">
    <News />
    <Slider />
  </div>
</template>

<script>
import Header from '../../src/components/Header'
import Slider from '../../src/components/Slider'
import News from '../../src/components/News'

export default {
  name: 'Home',
  components: {
    Header,
    Slider,
    News,
  },
}
</script>
