<style lang="scss" scoped>
.container-fluid {
  background-color: rgb(16, 30, 56);
}
p {
  color: #fff;
}
.text-spk {
  font-weight: lighter;
}
.title {
  font-size: 35px;
  font-weight: 800;
  text-transform: uppercase;
  margin: 0;
}
.first-letter {
  color: rgb(24, 174, 194);
}
.figure-box {
  margin: 0;
  padding: 0;
}
.nav {
  text-transform: uppercase;
  font-size: 17px;
}
@media (max-width: 1300px) {
  //Хедер
  .figure {
    display: none !important;
  }
}
</style>

<template>
  <b-container fluid style="border-bottom: 2px solid white">
    <b-row>
      <b-col cols="9">
        <b-row align-v="center">
          <b-col class="text-spk text-center ml-5 mt-2" cols="12">
            <p>
              <a :href="'http://spospk.ru/'">ОГБПОУ "СЕВЕРСКИЙ ПРОМЫШЛЕННЫЙ КОЛЛЕДЖ"</a>
            </p>
          </b-col>
          <b-col class="mt-n3" cols="4">
            <router-link to="/">
              <img class="img-fluid" src="../assets/img/icons/logo.svg" alt="logotype" />
            </router-link>
          </b-col>
          <b-col class="mt-n3" cols="4">
            <router-link to="/">
              <img class="img-fluid" src="../assets/img/newIcons/Logo_Prof_2024.svg" alt="logotype" />
            </router-link>
          </b-col>
          <b-col cols="3" offset-md="0">
            <p class="title"><span class="first-letter">с</span>тарт</p>
            <p class="title"><span class="first-letter">п</span>рофессиональной</p>
            <p class="title"><span class="first-letter">к</span>арьеры</p>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <b-navbar toggleable="md" type="dark">
              <b-navbar-toggle v-b-toggle.my-sidebar target="my-sidebar"></b-navbar-toggle>
              <b-collapse id="menu" is-nav>
                <b-nav>
                  <b-nav-item to="/">Главная</b-nav-item>
                  <div>
                    <b-dropdown
                      split
                      split-to="Specialties"
                      split-variant="transparrent"
                      variant="transparrent"
                      text="Специальности"
                      ref="dropdown"
                      left
                    >
                      <b-dropdown-item to="InfoSysAndProg"
                        >Информационные системы и программирование (cпециалист по информационным
                        системам)</b-dropdown-item
                      >
                      <b-dropdown-item to="InfoSysAndWeb"
                        >Информационные системы и программирование (разработчик веб и мультимедийных
                        приложений)</b-dropdown-item
                      >
                      <b-dropdown-item to="Coock">Поварское и кондитерское дело</b-dropdown-item>
                      <b-dropdown-item to="Law">Юриспруденция</b-dropdown-item>
                      <b-dropdown-item to="School">Дошкольное образование</b-dropdown-item>
                      <b-dropdown-item to="Electric">Тепловые электрические станции</b-dropdown-item>
                      <b-dropdown-item to="Station">Электрические станции, сети и системы</b-dropdown-item>
                      <b-dropdown-item to="Mounting"
                        >Монтаж, техническое обслуживание и ремонт промышленного оборудования (по
                        отраслям)</b-dropdown-item
                      >
                      <b-dropdown-item to="Locksmith">Мастер слесарных работ</b-dropdown-item>
                      <b-dropdown-item to="Electrician"
                        >Электромонтер по ремонту и обслуживанию электрооборудования</b-dropdown-item
                      >
                      <b-dropdown-item to="Portnoy">Мастер по изготовлению швейных изделий</b-dropdown-item>
                      <b-dropdown-item to="Prepodavaniye">Преподавание в начальных классах</b-dropdown-item>
                    </b-dropdown>
                  </div>

                  <b-nav-item to="Document">Документы</b-nav-item>
                  <b-nav-item to="Reiting">Рейтинги</b-nav-item>
                  <!--<b-nav-item href="http://spospk.ru/document/2021/%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3.pdf" target="_blank">Рейтинг</b-nav-item>-->
                  <b-nav-item to="Results">Результаты приема</b-nav-item>
                  <b-nav-item to="Contact">Контакты</b-nav-item>
                  <b-nav-item to="Galary">Фотогалерея</b-nav-item>
                </b-nav>
              </b-collapse>
              <b-sidebar id="my-sidebar" shadow backdrop>
                <b-navbar-nav style="padding-left: 20px">
                  <b-nav-item to="/">Главная</b-nav-item>
                  <div>
                    <b-nav-item-dropdown id="my-nav-dropdown" text="Специальности" toggle-class="nav-link-custom" left>
                      <b-dropdown-item to="InfoSysAndProg"
                        >Информационные системы и программирование (cпециалист по информационным
                        системам)</b-dropdown-item
                      >
                      <b-dropdown-item to="InfoSysAndWeb"
                        >Информационные системы и программирование (разработчик веб и мультимедийных
                        приложений)</b-dropdown-item
                      >
                      <b-dropdown-item to="Coock">Поварское и кондитерское дело</b-dropdown-item>
                      <b-dropdown-item to="Law">Право и организация социального обеспечения</b-dropdown-item>
                      <b-dropdown-item to="School">Дошкольное образование</b-dropdown-item>
                      <b-dropdown-item to="Electric">Тепловые электрические станции</b-dropdown-item>
                      <b-dropdown-item to="Station">Электрические станции, сети и системы</b-dropdown-item>
                      <b-dropdown-item to="Mounting"
                        >Монтаж, техническое обслуживание и ремонт промышленного оборудования (по
                        отраслям)</b-dropdown-item
                      >
                      <b-dropdown-item to="Locksmith">Мастер слесарных работ</b-dropdown-item>
                      <b-dropdown-item to="Electrician"
                        >Электромонтер по ремонту и обслуживанию электрооборудования</b-dropdown-item>
                      <b-dropdown-item to="Portnoy">Мастер по изготовлению швейных изделий</b-dropdown-item>
                      <b-dropdown-item to="Prepodavaniye">Преподавание в начальных классах</b-dropdown-item>
                    </b-nav-item-dropdown>
                  </div>
                  <b-nav-item to="Document">Документы</b-nav-item>
                  <b-nav-item to="Reiting">Рейтинги</b-nav-item>
                  <!--<b-nav-item href="http://spospk.ru/document/2021/%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3.pdf" rel="http://spospk.ru/document/2021/%D1%80%D0%B5%D0%B9%D1%82%D0%B8%D0%BD%D0%B3.pdf" target="_blank">Рейтинг</b-nav-item>-->
                  <b-nav-item to="Results">Результаты приема</b-nav-item>
                  <b-nav-item to="Contact">Контакты</b-nav-item>
                  <b-nav-item to="Galary">Фотогалерея</b-nav-item>
                </b-navbar-nav>
              </b-sidebar>
            </b-navbar>
          </b-col>
        </b-row>
      </b-col>
      <b-col class="figure-box" cols="3">
        <img class="img-fluid figure" src="../assets/img/icons/figur.svg" alt="figure" />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  methods: {
    // onOver() {
    //   this.$refs.dropdown.visible = true
    // },
    // onLeave() {
    //   this.$refs.dropdown.visible = false
    // },
  },
}
</script>