<style lang="scss" scoped>
//Основные стили
p {
  color: #000;
  text-align: justify;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.name {
  font-size: 25px;
  color: rgb(24, 174, 194);
  text-indent: 30px;
}
img {
  width: 200px;
}
//Адаптив
@media (max-width: 575.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) { 
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .carousel-caption p {
    font-size: 15px;
  }
}
</style>
<template>
  <b-carousel
  id="carousel-1"
  :interval="10000"
  indicators
  img-width="1024"
  img-height="280"
  class="carusel-student mb-3 pb-5">
    <b-carousel-slide img-blank img-alt="Blank image">
      <b-container>
        <b-row>
          <b-col cols="8" offset="3">
            <p class="name mb-0 mb-lg-2">
              Игорь, выпускник 2019 года.
            </p>
          </b-col>
          <b-col class="d-none d-lg-block" cols="3">
            <img class="img-fluid" src="../assets/img/others/3.png" alt="student">
          </b-col>
          <b-col cols="12" lg="8">
            <p>
              Как выпускник Северского промышленного колледжа могу сказать, что очень рад тому, что поступил учиться
              именно в этот колледж! Я могу сказать, что наш колледж замечательный, именно в этом колледже могут дать нужные
              знания, которые пригодятся в дальнейшем! Коллектив преподавателей в этой организации отличный. Педагоги колледжа
              компетентные, доброжелательные, грамотные и знающие свое дело. Те знания, которые они нам дали, действительно,
              очень помогают в моей работе. Хочу выразить огромную благодарность всему педагогическому коллективу за чудесный мир,
              которые они нам открыли
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-carousel-slide>
    <b-carousel-slide img-blank img-alt="Blank image">
      <b-container>
        <b-row>
          <b-col cols="8" offset="3">
            <p class="name mb-0 mb-lg-2">
              Игорь, выпускник 2019 года.
            </p>
          </b-col>
          <b-col class="d-none d-lg-block" lg="3">
            <img class="img-fluid" src="../assets/img/others/3.png" alt="student">
          </b-col>
          <b-col cols="12" lg="8">
            <p>
              Как выпускник Северского промышленного колледжа могу сказать, что очень рад тому, что поступил учиться
              именно в этот колледж! Я могу сказать, что наш колледж замечательный, именно в этом колледже могут дать нужные
              знания, которые пригодятся в дальнейшем! Коллектив преподавателей в этой организации отличный. Педагоги колледжа
              компетентные, доброжелательные, грамотные и знающие свое дело. Те знания, которые они нам дали, действительно,
              очень помогают в моей работе. Хочу выразить огромную благодарность всему педагогическому коллективу за чудесный мир,
              которые они нам открыли.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-carousel-slide>
    <b-carousel-slide img-blank img-alt="Blank image">
      <b-container>
        <b-row>
          <b-col cols="8" offset="3">
            <p class="name mb-0 mb-lg-2">
              Игорь, выпускник 2019 года.
            </p>
          </b-col>
          <b-col class="d-none d-lg-block" lg="3">
            <img class="img-fluid" src="../assets/img/others/3.png" alt="student">
          </b-col>
          <b-col cols="12" lg="8">
            <p>
              Как выпускник Северского промышленного колледжа могу сказать, что очень рад тому, что поступил учиться
              именно в этот колледж! Я могу сказать, что наш колледж замечательный, именно в этом колледже могут дать нужные
              знания, которые пригодятся в дальнейшем! Коллектив преподавателей в этой организации отличный. Педагоги колледжа
              компетентные, доброжелательные, грамотные и знающие свое дело. Те знания, которые они нам дали, действительно,
              очень помогают в моей работе. Хочу выразить огромную благодарность всему педагогическому коллективу за чудесный мир,
              которые они нам открыли.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
export default {
  
}
</script>
