<style lang="scss" scoped>
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
ul {
  font-size: 18px !important;
}
ul li {
  list-style: none;
}
ul li::before {
  content: '-';
  padding-right: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Информация для иногородних абитуриентов</h1>
      </b-col>
    </b-row>
    <b-row class="mb-5">
      <b-col cols="12">
        <p>
          <b> Подача документов для иногородних абитуриентов производиться в дистанционном формате. </b>
          Подать заявление в дистанционном режиме возможно, отправив на электронную почту
          <b>postupi@spospk.ru </b>отсканированные копии документов:
        </p>
        <ul>
          <li><a download="Заявление абитуриента 2023-2024 на базе 9 классов"
              target="_blank" href="/file/Заявление_абитуриента_2023_2024_на_база_9_классов.docx" class="ist">Заполненное заявление</a> (на базе 9 классов);</li>
          <li><a download="Заявление абитуриента 2023-2024 на базе 11 классов"
              target="_blank" href="/file/Заявление_абитуриента_2023_2024_на_база_11_классов.docx" class="ist">Заполненное заявление</a> (на базе 11 классов);</li>
          <li>
            <a class="ist" download="Заполненное согласие на обработку персональных данных от абитуриента (заполняется абитуриентом).doc" href="/file/orderten.doc">
              Заполненное согласие на обработку персональных данных от абитуриента
            </a>
          </li>
          <li>
            <a class="ist" download="Заполненное согласие на обработку персональных данных законного представителя (заполняется законным представителем абитуриента).doc"  href="/file/ordernine.doc">
              Заполненное согласие на обработку персональных данных законного представителя (заполняется законным представителем абитуриента, не достигшего возраста 18 лет)
            </a>
          </li>
          <li>Паспорт (разворот с фотографией и прописка);</li>
          <li>Документ об образовании (аттестат с приложением);</li>
          <li>ИНН;</li>
          <li>СНИЛС;</li>
          <li>Военный билет/удостоверение гражданина, подлежащего призыву на военную службу/ (для юношей);</li>
          <li>
            Паспорт (разворот с фотографией и прописка) и ИНН одного из родителей (ПОСТУПАЮЩИМ НА ПЛАТНОЙ ОСНОВЕ).
          </li>
        </ul>
        <p>
          Ответственный секретарь приемной комиссии, получив на электронную почту <b>postupi@spospk.ru</b> заполненное
          заявление и пакет документов от иногороднего абитуриента, направляет запрос в Администрацию ЗАТО Северск с
          целью оформления временного пропуска. Информация о дате разрешения въезда в ЗАТО Северск и предоставлении
          оригиналов документов в приемную комиссию иногороднего абитуриента секретарь приемной комиссии информирует по
          номеру телефона, указанного в заявлении абитуриента.
        </p>
        <p>
          <b>Подать заявление также возможно:</b> 
          <ul>
            <p></p>
            <li>
              через операторов почтовой связи общего пользования по адресу: ул. Крупской,
              17, г. Северск Томской области, 636036.
            </li>

            <li>
              через Портал «ГОСУСЛУГИ».
            </li>
          </ul>
        </p>
        <p>
          <b>
            ОГБПОУ «Северский промышленный колледж» общежития не имеет
          </b>
          и не обеспечивает поступающих жилыми помещениями.
        </p>
        <p>
          <b>
            Справки по телефонам 8(3823) 780-327, 780-333, 780-366
          </b>
        </p>
        <p>
          пн. - чт. 9.00 - 17.00
          <br>
          пт. 9.00 - 16.00
        </p>
        <p>
          ПРИЕМНАЯ КОМИССИЯ ОГБПОУ "СПК": <br> Адрес: г. Северск, ул. Крупской, 17, ауд. 112. <br>
          Телефон: +7(3823) 780-327; +780-333; 780-366 <br> E-mail: postupi@spospk.ru
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          text: 'Информация для иногородних абитуриентов',
          actrive: true,
        },
      ],
    }
  },
}
</script>