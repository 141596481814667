<style lang="scss" scoped>
section.ymap-container {
  width: 100%;
  height: 100%;
}
</style>
<template>
<yandex-map 
  :coords="coords"
  :zoom="18"
>
  <ymap-marker 
    :coords="coords" 
    marker-id="123" 
    hint-content="ОГБПОУ Северский промышленный колледж" 
  />
</yandex-map>
</template>

<script>
export default {
  data: () => ({
    coords: [
      56.6006285681234,
      84.89297749999999,
    ],
  }),
};

</script>