<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
.pdf li,
.word li,
.link {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 30px;
}
li a {
  font-weight: bold;
  color: rgb(16, 30, 56) !important;
  font-size: 22px;
}
.pdf li::before {
  content: '';
  position: absolute;
  background: url('../assets/img/icons/pdf.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  left: 10px;
}
.word li::before {
  content: '';
  position: absolute;
  background: url('../assets/img/icons/word.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  left: 10px;
}
</style>


<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Документы</h1>
      </b-col>
      <b-col cols="12">
        

        <ul class="pdf">
          <!-- <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/План приема.pdf`"
            >
              План приема
            </a>
          </li> -->

          <li>
            <a
              target="_blank"
              :href="`${publicPath}./file/Приказ о зачислении на 1 курс БЮДЖЕТ.pdf`"
            >
              Приказ о зачислении на 1 курс БЮДЖЕТ
            </a>
          </li>
          <li>
            <a
              target="_blank"
              :href="`${publicPath}./file/Приказ о зачислении на 1 курс ВНЕБЮДЖЕТ.pdf`"
            >
              Приказ о зачислении на 1 курс ВНЕБЮДЖЕТ
            </a>
          </li>
          <li>
            <a
              target="_blank"
              :href="`${publicPath}./file/Приказ о зачислении на 1 курс заочное ВНЕБЮДЖЕТ 30.09.24.pdf`"
            >
              Приказ о зачислении на 1 курс заочное ВНЕБЮДЖЕТ
            </a>
          </li>

          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/План приема 2024 посл ред.pdf`"
            >
              План приема на 2024 учебный год
            </a>
          </li>

          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/ПОЛОЖЕНИЕ об экзаменационных комиссиях СПК_2024.pdf`"
            >
              Положение об экзаменационной комиссии
            </a>
          </li>
          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/Приказ о введении в действие правил, положений 2024.pdf`"
            >
              Приказ о введении в действие правил, положений
            </a>
          </li>
          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/Правила приема в СПК 2024.pdf`"
            >
              Правила приема на 2024 год
            </a>
          </li>
          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/Программа вступительных испытаний СПК_2024.pdf`"
            >
              Программа вступительных испытаний 2024
            </a>
          </li>
          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/Положение о деятельности приемной комиссии СПК 2024.pdf`"
            >
              Положение о приемной комиссии
            </a>
          </li>
          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/Положение об апелляционной комиссии СПК_2024.pdf`"
            >
              Положение об апелляционной комиссии
            </a>
          </li>

          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/orderfor.pdf`"
            >
              Положение об оказании платных образовательных услуг
            </a>
          </li>
          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/Измения в Устав.pdf`"
            >
              Изменения в Устав
            </a>
          </li>
          <li>
            <a
              
              target="_blank"
              :href="`${publicPath}./file/Устав.pdf`"
            >
              Устав
            </a>
          </li>
        </ul>
        <ul class="word">
          <li>
            <a
              target="_blank"
              :href="`${publicPath}./file/Образец_договора_о_целевом_обучении_СПО.docx`"
            >
              Образец договора о целевом обучении СПО
            </a>
          </li>
          <li>
            <a
              download="Заявление абитуриента 2023-2024 на базе 11 классов"
              target="_blank"
              :href="`${publicPath}./file/Заявление абитуриента 2023-2024 на базе 9 классов 2024.docx`"
            >
              Заявление абитуриента на базе 9 классов 
            </a>
          </li>
          <li>
            <a
              download="Заявление абитуриента 2023-2024 на базе 11 классов"
              target="_blank"
              :href="`${publicPath}./file/Заявление абитуриента 2023-2024 на базе 11 классов 2024.docx`"
            >
              Заявление абитуриента  на базе 11 классов 
            </a>
          </li>
          <li>
            <a
              download="Образец договора на  2023-2024 учебный год  2-х сторонний"
              target="_blank"
              :href="`${publicPath}./file/Образец_договора_на_2023_2024_учебный_год_2_х_сторонний.docx`"
            >
              Образец договора  2-х сторонний
            </a>
          </li>
          <li>
            <a
              download="Образец договора на  2023-2024 учебный год  3-х сторонний"
              target="_blank"
              :href="`${publicPath}./file/Образец_договора_на_2023_2024_учебный_год_3_х_сторонний.docx`"
            >
              Образец договора  3-х сторонний
            </a>
          </li>
          <li>
            <a
              download="Заполненное согласие на обработку персональных данных от абитуриента (заполняется абитуриентом).doc"
              target="_blank"
              :href="`${publicPath}./file/Заполненное согласие на обработку персональных данных от абитуриента (заполняется абитуриентом).doc`"
            >
              Согласие на обработку персональных данных от абитуриента
            </a>
          </li>
          <li>
            <a
              download="Заполненное согласие на обработку персональных данных законного представителя (заполняется законным представителем абитуриента).doc"
              target="_blank"
              :href="`${publicPath}./file/Заполненное согласие на обработку персональных данных законного представителя (заполняется законным представителем абитуриента).doc`"
            >
              Согласие на обработку персональных данных от законного представителя абитуриента
            </a>
          </li>
        </ul>
        <ul class="pdf">
          <li>
            <a
              target="_blank"
              :href="`${publicPath}./file/Приказ об установлении оплаты за обучение 2024-2025 уч.год.pdf`"
            >
              Приказ об установлении оплаты за обучение 2024-2025 уч.год
            </a>
          </li>
        </ul>
        <!-- <ul class="link">
          <li>
            <a href="/Specialties">
              План приёма на 2023/2024 учебный год
            </a>
          </li>
        </ul> -->
        <!--<ul class="link">
          <li>
            <a href="/Oplata">
              Стоимость обучения для поступающих на 1 курс в 2022-2023 учебном году
              </a>
          </li>
        </ul> -->
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          text: 'Документы',
          active: true,
        },
      ],
    }
  },
}
</script>