<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
a {
  color: rgb(16, 30, 56) !important;
  text-decoration: underline;
}

</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>
          Специальности
        </h1>
      </b-col>
      <b-col cols="12">
        <b-table-simple small bordered responsive>
          <colgroup>
            <col span="1">
            <col span="1">
            <col span="1">
          </colgroup>
          <colgroup>
            <col span="1" style=" width: 100px">
          </colgroup>
          <b-tbody>
            <b-tr>
              <b-th colspan="1">Шифр</b-th>
              <b-th colspan="1">Специальность</b-th>
              <b-th colspan="1">Количество мест</b-th>
              <b-th colspan="1">Срок обучения</b-th>
              <b-th colspan="1">Квалификация</b-th>
              <b-th colspan="1">Образование</b-th>
            </b-tr>
            <b-tr>
              <b-th colspan="6">
                Программы подготовки специалистов среднего звена
              </b-th>
            </b-tr>
            <b-tr>
              <b-th colspan="6">
                Очная (дневная) форма обучения
              </b-th>
            </b-tr>
            <b-tr>
              <b-td>13.02.01</b-td>
              <b-td>Тепловые электрические станции</b-td>
              <b-td>25 бюджет</b-td>
              <b-td>3 г. 10 мес.</b-td>
              <b-td>
                <b-link to="Electric">
                  <em>
                    Техник - теплотехник
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>13.02.03</b-td>
              <b-td>Электрические станции, сети, их релейная защита и автоматизация (в рамках ФП «Профессионалитет») </b-td>
              <b-td>25 бюджет</b-td>
              <b-td>3 г. 6 мес.</b-td>
              <b-td>
                <b-link to="Station">
                  <em>
                    Техник-электрик
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>15.02.12</b-td>
              <b-td>Монтаж, техническое обслуживание, эксплуатация и ремонт промышленного оборудования (по отраслям) (в рамках ФП «Профессионалитет»)</b-td>
              <b-td>25 бюджет <br> 25 платно </b-td>
              <b-td>3 г. 6 мес.</b-td>
              <b-td>
                <b-link to="Mounting">
                  <em>
                    Техник-механик
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td rowspan="3">09.02.07</b-td>
              <b-td rowspan="3">Информационные системы и программирование (ТОП-50)</b-td>
              <b-td>25 бюджет <br> 5 платно</b-td>
              <b-td>2 г. 10 мес.</b-td>
              <b-td rowspan="2">
                <b-link to="InfoSysAndProg">
                  <em>
                    Специалист по информационным системам
                  </em>
                </b-link>
              </b-td>
              <b-td>11 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>25 бюджет <br> 25 платно</b-td>
              <b-td>3 г. 10 мес.</b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>25 бюджет</b-td>
              <b-td>3 г. 10 мес.</b-td>
              <b-td>
                <b-link to="InfoSysAndWeb">
                  <em>
                    Разработчик  веб и мультимедийных приложений
                  </em>
                </b-link> 
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td rowspan="2">44.02.01</b-td>
              <b-td rowspan="2">Дошкольное образование</b-td>
              <b-td>25 бюджет</b-td>
              <b-td>3 г. 10 мес.</b-td>
              <b-td rowspan="2">
                <b-link to="School">
                  <em>
                    Воспитатель детей дошкольного возраста
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              
            </b-tr>
            
            <b-tr>
              <b-td rowspan="2">44.02.02</b-td>
              <b-td rowspan="2">Преподавание в начальных классах</b-td>
              <b-td>25 бюджет <br> 25 платно </b-td>
              <b-td>3 г. 10 мес.</b-td>
              <b-td rowspan="2">
                <b-link to="Prepodavaniye">
                  <em>
                    Учитель начальных классов
                  </em>
                  
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>20 бюджет</b-td>
              <b-td>2 г. 10 мес.</b-td>
              <b-td>11 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td rowspan="2">40.02.04</b-td>
              <b-td rowspan="2">Юриспруденция</b-td>
              <b-td>10 платно</b-td>
              <b-td>1 г. 10 мес.</b-td>
              <b-td rowspan="2">
                <b-link to="Law">
                  <em>
                    Юрист
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>25 платно</b-td>
              <b-td>2 г. 10 мес.</b-td>
              <b-td>11 классов</b-td>
            </b-tr>
            <b-tr>

            </b-tr>
            <b-tr>
              <b-td rowspan="2">43.02.15</b-td>
              <b-td rowspan="2">Поварское и кондитерское дело  (ТОП-50)</b-td>
              <b-td>25 платно</b-td>
              <b-td>3 г. 10 мес.</b-td>
              <b-td rowspan="2">
                <b-link to="Coock">
                  <em>
                    Специалист по поварскому и кондитерскому делу
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>10 платно</b-td>
              <b-td>2 г. 10 мес.</b-td>
              <b-td>11 классов</b-td>
            </b-tr>
            <b-tr>
              <b-th colspan="6">
                Заочная форма обучения
              </b-th>
            </b-tr>
            <b-tr>
              <b-td>44.02.01</b-td>
              <b-td>Дошкольное образование</b-td>
              <b-td>25 платно</b-td>
              <b-td>3 г. 10 мес.</b-td>
              <b-td>
                <b-link to="School">
                  <em>
                    Воспитатель детей дошкольного возраста
                  </em>
                </b-link>
              </b-td>
              <b-td>11 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>40.02.04</b-td>
              <b-td>Юриспруденция</b-td>
              <b-td>25 платно</b-td>
              <b-td>2 г. 10 мес.</b-td>
              <b-td>
                <b-link to="Law">
                  <em>
                    Юрист
                  </em>
                </b-link>
              </b-td>
              <b-td>11 классов</b-td>
            </b-tr>
            <b-tr>
              <b-th colspan="6">
                ПРОГРАММЫ ПОДГОТОВКИ КВАЛИФИЦИРОВАННЫХ РАБОЧИХ (СЛУЖАЩИХ) 
              </b-th>
            </b-tr>
            <b-tr>
              <b-th colspan="6">
                Очная (дневная) форма обучения (образовательные программы СПО)
              </b-th>
            </b-tr>
            <b-tr>
              <b-td>29.01.07</b-td>
              <b-td>Мастер по изготовлению швейных изделий</b-td>
              <b-td>25 бюджет</b-td>
              <b-td>1 г. 10 мес.</b-td>
              <b-td>
                <b-link to="Portnoy">
                  <em>
                    Мастер по изготовлению швейных изделий
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
            <b-tr>
              <b-td>15.01.35</b-td>
              <b-td>Мастер слесарных работ (в рамках ФП «Профессионалитет»)</b-td>
              <b-td>50 бюджет</b-td>
              <b-td>2 г. 6 мес.</b-td>
              <b-td>
                <b-link to="Locksmith">
                  <em>
                    Слесарь-ремонтник, слесарь механосборочных работ, слесарь-инструментальщик 
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>

            <b-tr>
              <b-td>13.01.10</b-td>
              <b-td>Электромонтер по ремонту и обслуживанию электрооборудования (в рамках ФП «Профессионалитет»)</b-td>
              <b-td>25 бюджет</b-td>
              <b-td>1 г. 10 мес.</b-td>
              <b-td>
                <b-link to="Electrician">
                  <em>
                    Электромонтер по ремонту и обслуживанию электорооборудования
                  </em>
                </b-link>
              </b-td>
              <b-td>9 классов</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Specialties",
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          }
        ]
      }
    }
}
</script>
