<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
.pdf li,
.word li {
  list-style: none;
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: 30px;
}
li a {
  font-weight: bold;
  color: rgb(16, 30, 56) !important;
  font-size: 22px;
}
.pdf li::before {
  content: '';
  position: absolute;
  background: url('../assets/img/icons/pdf.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  left: 10px;
}
.word li::before {
  content: '';
  position: absolute;
  background: url('../assets/img/icons/word.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  left: 10px;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Результаты приема</h1>
      </b-col>
      <b-col cols="12" class="mb-5">
        <ul class="pdf">
          <li>
            <a download="Итоги приема 2019 год" target="_blank" :href="`${publicPath}./file/itog2019.pdf`">
              Итоги приема 2019 год
            </a>
          </li>
          <li>
            <a download="Итоги приема 2020 год" target="_blank" :href="`${publicPath}./file/itog2020.pdf`">
              Итоги приема 2020 год
            </a>
          </li>
          <li>
            <a download="Итоги приема 2021 год" target="_blank" :href="`${publicPath}./file/itog2021.pdf`">
              Итоги приема 2021 год
            </a>
          </li>
          <li>
            <a download="Итоги приема 2022 год" target="_blank" :href="`${publicPath}./file/itog2022.pdf`">
              Итоги приема 2022 год
            </a>
          </li>
          <li>
            <a download="Итоги приема 2023 год" target="_blank" :href="`${publicPath}./file/itog2023.pdf`">
              Итоги приема 2023 год
            </a>
          </li>
           <li>
            <a download="Итоги приема 2024 год" target="_blank" :href="`${publicPath}./file/Itogi_priema_2024.pdf`">
              Итоги приема 2024 год
            </a>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      publicPath: process.env.BASE_URL,
      items: [
        {
          text: 'Результаты приема',
          active: true,
        },
      ],
    }
  },
}
</script>