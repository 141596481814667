<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
.info {
  font-weight: 600;
}
.text {
  text-decoration: underline;
}
</style>
<template>
  <b-container class="mb-5">
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>
          Контакты
        </h1>
      </b-col>
      <b-col cols="6" offset="1">
         <Map />
      </b-col>
      <b-col class="info" cols="5">
        <p>
          <em>
            Адрес
          </em>
        </p>
        <p>
          г.Северск, ул. Крупской, 17, каб. 104.
        </p>
        <p>
          <em>
            Телефон
          </em>
        </p>
        <p>
          +7(3823) 780-327 <br>
          +7(3823) 780-366 <br>
          +7(3823) 780-333
        </p>
        <p>
          <em>
            Электронная почта
          </em>
        </p>
        <p>
          postupi@spospk.ru
        </p>
        <p>
          <em>
            График работы
          </em>
        </p>
        <p>
          пн-чт: 8:00 - 17:00 <br>
          пт: 8:00 - 16:00
        </p>
        <p class="text">
          Перерыв на обед: 12:00 - 12:45
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Map from "../components/Map";
export default {
  components: { 
    Map
   },
  data() {
      return {
        items: [
          {
            text: 'Контакты',
            href: '/Contact'
          }
        ],
      }
    },
}
</script>