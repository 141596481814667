<style lang="scss" scoped>
//Основные стили
p {
  color: #000;
  text-align: justify;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.name {
  font-size: 25px;
  color: rgb(24, 174, 194);
  text-indent: 30px;
}
img {
  width: 200px;
}
//Адаптив
@media (max-width: 575.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) { 
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .carousel-caption p {
    font-size: 15px;
  }
}
</style>
<template>
  <b-carousel
  id="carousel-1"
  :interval="10000"
  indicators
  img-width="1024"
  img-height="280"
  class="carusel-student mb-3 pb-5">
    <b-carousel-slide img-blank img-alt="Blank image">
      <b-container>
        <b-row>
          <b-col cols="8" offset="3">
            <p class="name mb-0 mb-lg-2">
              Илья, выпускник 2020 года.
            </p>
          </b-col>
          <b-col class="d-none d-lg-block" cols="3">
            <img class="img-fluid" src="../assets/img/others/nikolaev.png" alt="student">
          </b-col>
          <b-col cols="12" lg="8">
            <p>
              Если бы мне пришлось еще раз выбирать, я бы пошел именно в СПК колледж. В колледже по 
              сравнению со школой стал лучше учиться, возможно, потому что взрослеешь и начинаешь понимать, 
              что это нужно именно тебе. Становился именным стипендиатом Сибирского химического комбината. 
              Я благодарен всем своим преподавателям, чему смогли – научили. Сегодня я работаю на АО 
              «СХК», трудоустроиться мне помогли знания, полученные в СПК.
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-carousel-slide>
    <b-carousel-slide img-blank img-alt="Blank image">
      <b-container>
        <b-row>
          <b-col cols="8" offset="3">
            <p class="name mb-0 mb-lg-2">
              Климентий, выпускник 2014 года.
            </p>
          </b-col>
          <b-col class="d-none d-lg-block" lg="3">
            <img class="img-fluid" src="../assets/img/others/kamnev.png" alt="student">
          </b-col>
          <b-col cols="12" lg="8">
            <p>
              Благодаря сильному и опытному преподавательскому коллективу в СПК готовят очень хороших 
              специалистов по любым направлениям. Полученный уровень подготовки, несомненно, мне помог 
              в профессиональной деятельности, и соответствует требованиям современной энергетики. 
              Колледж также даёт большой жизненный и социальный опыт, развивает организаторские и 
              лидерские качества студента!
              В настоящее время - электромонтёр по оперативным переключениям в распределительных сетях 
              ЦЭРЭО ЗРИ АО «СХК».
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
export default {
  
}
</script>
