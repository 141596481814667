<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 18px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="InfoSysAndWeb">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Информационные системы и программирование
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>разработчик веб и мультимедийных приложений</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Разработчик веб и мультимедийных приложений. Одна из самых актуальных профессий в отрасли! 
              Ни одна организация сейчас не обходится без веб-сайта и Интернет-приложения. Разработчик Web 
              и мультимедийных приложений – программист, который занимается созданием, изменением и 
              сопровождением web-сайтов, корпоративных порталов организаций, мультимедиа и интерактивных 
              приложений, используя современные языки программирования  и средства интерактивной графики. 
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/web.jpg" alt="Информационные системы">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 3 года 10 месяцев (на базе 9 классов), 2 года 10 месяцев 
            (на базе 11 классов).  Форма обучения очная.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Логическое, алгоритмическое и математическое мышление; <br>
                    начальные  навыки программирования; <br>
                    навыки формализации и структурирования информации; <br>
                    базовые навыки работать в офисных программах и Интернет-приложениях; <br>
                    навыки безопасного поведения при работе с компьютерными программами и в Интернете <br>
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Информационные технологии и платформы разработки информационных систем. 
                      Компьютерные сети. Операционные системы и среды. Алгоритмизацию и 
                      программированние. Базы данных. Управление проектами. Проектирование и 
                      разработка информационных систем. Программирование 1С. Графический дизайн веб-приложений.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Занимается разработкой, сопровождением и внедрением различных информационных систем. 
                      Разрабатывает, модификацирует и сопровождает web-сайты, корпоративные порталы организаций, 
                      мультимедиа и интерактивные приложения, информационные  ресурсы с использованием 
                      мультимедийных средств, интерактивной графики и языков программирования.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: способность работать с массивными базами данных; стремление к 
                      саморазвитию, целеустремленность; оригинальность, находчивость, креативность; способность 
                      к кропотливой и внимательной деятельности; развитое техническое мышление. <br>
                      Личные качества: внимательность;  аккуратность; терпеливость; настойчивость  
                      целеустремленность; ответственность; склонность к интеллектуальным видам деятельности;
                      умение самостоятельно принимать решения; независимость (наличие собственного мнения).
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Разработка и интеграция модулей программного обеспечения для компьютерных систем <br>
                      Анализ и тестирование программных продуктов <br>
                      Сопровождение и обслуживание программного обеспечения компьютерных систем <br>
                      Проектирование, разработка и сопровождение информационных систем <br>
                      Разработка, администрирование и защита баз данных, серверов и информационных ресурсов <br>
                      Разработка дизайна веб-приложений <br>
                      Проектирование, разработка и оптимизация веб-приложений <br>
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <!-- <SliderStudents /> -->
    </b-container>
  </div>
</template>

<script>
import SliderStudents from "../../src/components/SliderStudents";

export default {
  name: "InfoSysAndWeb",
  components: {
    SliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Информационные системы и программирование (разработчик веб и мультимедийных приложений)',
            active: true
          }
        ]
      }
    }
};
</script>