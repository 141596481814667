<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 25px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="Station">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Электрические станции, сети и системы
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>техник-электрик</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Техники-электрики - это профессионал в ремонте и эксплуатации электрического оборудования и сетей. 
              В их компетенции не только  бытовые электроприборы и проводка, но и производственные мощности 
              на электростанциях и промышленных предприятиях.
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/station.jpg" alt="Информационные системы">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 3 года 10 месяцев (на базе 9 классов). Форма обучения очная.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Базовые знания физики и математики; <br>
                    Математическое  и логическое мышление; <br>
                    Пространственно-образное мышление; <br>
                    Расчетно-вычислительные навыки; <br>
                    Базовые навыки работы с чертежами и схемами; <br>
                    Базовые технические навыки; <br>
                    Навыки простых экспериментальных исследований; <br>
                    Навыки безопасного использования электрических приборов; <br>
                    Гибкость и координация движения кистей и пальцев рук.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Основы электротехники. Виды электротехнических материалов, марки проводов и кабелей. 
                      Чтение электрических схем. Сведения о деталях электрооборудования и их соединениях. 
                      Электрические измерения. Основы электромонтажных работ. Правила и способы монтажа и 
                      ремонта электрооборудования
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Выполняет технические задачи в области электротехники, а также проектирования, изготовления, 
                      монтажа, строительства, эксплуатации, технического обслуживания и ремонта электрооборудования, 
                      средств и систем распределения электроэнергии в любых помещений и сооружений.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: гибкость мышления; наглядно-образное мышление; высокий уровень 
                      концентрации, устойчивости внимания; хорошая память; пространственное воображение; 
                      способность принимать и внедрять новое на практике; аналитические способности. <br>
                      Личные качества: рациональность; целеустремленность, инициативность, настойчивость; 
                      наблюдательность, самостоятельность; изобретательность,  терпеливость, аккуратность, 
                      коммуникабельность; технический склад ума, внимательность, хорошая память.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Проведение технического обслуживания электрооборудования электрических станций, 
                      сетей и систем. Осмотр, монтаж и демонтаж электрооборудования. Оформление 
                      технической документации. Контроль работы оборудования,  распределения электроэнергии 
                      и управление ею,  Контроль показателей работы электрооборудования Планирование работы 
                      производственного подразделения. Проведение мероприятий по охране труда, технике 
                      безопасности и бережливому производству.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <StationSliderStudents />
    </b-container>
  </div>
</template>

<script>
import StationSliderStudents from "../../src/components/StationSliderStudents";

export default {
  name: "Station",
  components: {
    StationSliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Электрические станции, сети и системы',
            active: true
          }
        ]
      }
    }
};
</script>