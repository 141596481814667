<style lang="scss" scoped>
.container-fluid {
  background-color: #263042;
  color: #fff;
}
</style>

<template>
  <b-container fluid>
    <b-row class="pt-3">
      <b-col cols="12" md="6" class="text-center">
        <p>
          Информация об учреждении
        </p>
        <p class="pl-5">
          <a :href="'http://spospk.ru/'">Областное государственное бюджетное профессиональное образовательное
          учреждение "Северский промышленный колледж»</a>
        </p>
      </b-col>
      <b-col cols="12" md="6">
        <p class="text-center">
          Приёмная комиссия
        </p>
        <p class="pl-5">
          Адрес: г. Северск, Томская область, ул. Крупской, 17, каб. 104 <br />
          Почтовый адрес: 636036, Томская область, г. Северск, ул. Крупской, 17 <br />
          Телефон: +7(3823) 780-327, 780-333, 780-366 <br />
          E-mail: postupi@spospk.ru
        </p>
      </b-col>
    </b-row>
    <b-row class="text-center">
      <b-col cols="12">        
        <p>
          Северский промышленный колледж 2020. Все права защищены.
        </p>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {};
</script>
