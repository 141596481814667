import Vue from "vue";
import Vuex from "vuex";
import YmapPlugin from 'vue-yandex-maps'


Vue.use(Vuex);
// Vue.use(YmapPlugin, settings);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {}
});
