import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import InfoSysAndProg from "../views/InfoSysAndProg.vue";
import InfoSysAndWeb from "../views/InfoSysAndWeb.vue";
import Coock from "../views/Coock.vue";
import School from "../views/School.vue";
import Electric from "../views/Electric.vue";
import Station from "../views/Station.vue";
import Mounting from "../views/Mounting.vue";
import Locksmith from "../views/Locksmith.vue";
import Electrician from "../views/Electrician.vue";
import Specialties from "../views/Specialties.vue";
import Contact from "../views/Contact.vue";
import Document from "../views/Document.vue";
import Law from "../views/Law.vue";
import Director from "../views/Director.vue";
import SpecNews from "../views/SpecNews.vue";
import InfoAboutDoc from "../views/InfoAboutDoc.vue";
import InfoForAbitur from "../views/InfoForAbitur.vue";
import InfoForInvalid from "../views/InfoForInvalid.vue";
import Results from "../views/Results.vue";
import PrelInfo from "../views/PrelInfo.vue";
import Schedule from "../views/Schedule.vue";
import Rasp from "../views/Rasp.vue";
import Portnoy from "../views/Portnoy.vue";
import Prepodavaniye from "../views/Prepodavaniye.vue";
import Reiting from "../views/Reiting.vue";
import Oplata from "../views/Oplata.vue";
import Galary from '../views/Galary.vue';
import InfoAboutDocDate from '../views/InfoAboutDocDate.vue';
import SubmitDocuments from '../views/SubmitDocuments.vue';
import InfoAboutSubmit from '../views/InfoAboutSubmit.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta: {
      title: 'Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/InfoSysAndProg",
    name: "InfoSysAndProg",
    component: InfoSysAndProg,
    meta: {
      title: 'Информационные системы и программирование (специалист по информационным системам): Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/InfoSysAndWeb",
    name: "InfoSysAndWeb",
    component: InfoSysAndWeb,
    meta: {
      title: 'Информационные системы и программирование (разработчик веб и мультимедийных приложений): Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Galary",
    name: "Galary",
    component: Galary,
    meta: {
      title: 'Фотогалерея'
    }
  },
  {
    path: "/Coock",
    name: "Coock",
    component: Coock,
    meta: {
      title: 'Поварское и кондитерское дело: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/School",
    name: "School",
    component: School,
    meta: {
      title: 'Дошкольное образование: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Electric",
    name: "Electric",
    component: Electric,
    meta: {
      title: 'Тепловые электрические станции: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Station",
    name: "Station",
    component: Station,
    meta: {
      title: 'Электрические станции, сети и системы: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Mounting",
    name: "Mounting",
    component: Mounting,
    meta: {
      title: 'Монтаж, техническое обслуживание и ремонт промышленного оборудования (по отраслям): Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Locksmith",
    name: "Locksmith",
    component: Locksmith,
    meta: {
      title: 'Мастер слесарных работ: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Electrician",
    name: "Electrician",
    component: Electrician,
    meta: {
      title: 'Электромонтер по ремонту и обслуживанию электрооборудования: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Specialties",
    name: "Specialties",
    component: Specialties,
    meta: {
      title: 'Специальности: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: 'Контакты: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Document",
    name: "Document",
    component: Document,
    meta: {
      title: 'Документы: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Reiting",
    name: "Reiting",
    component: Reiting,
    meta: {
      title: 'Рейтинг'
    }
  },
  {
    path: "/Oplata",
    name: "Oplata",
    component: Oplata,
    meta: {
      title: 'Стоимость обучения'
    }
  },
  {
    path: "/Law",
    name: "Law",
    component: Law,
    meta: {
      title: 'Право и организация социального обеспечения: Абитуриенту Северского промышленного колледжа'
    }
  },
  {
    path: "/Director",
    name: "Director",
    component: Director,
    meta: {
      title: 'Обращение директора к абитуриентам'
    }
  },
  {
    path: "/InfoForInvalid",
    name: "InfoForInvalid",
    component: InfoForInvalid,
    meta: {
      title: 'Информация о приеме документов от лиц с ОВЗ и инвалидов'
    }
  },
  {
    path: "/InfoForAbitur",
    name: "InfoForAbitur",
    component: InfoForAbitur,
    meta: {
      title: 'Информация для иногородних абитуриентов'
    }
  },
  {
    path: "/InfoAboutDoc",
    name: "InfoAboutDoc",
    component: InfoAboutDoc,
    meta: {
      title: 'Информация о сроках подачи документов'
    }
  },
  {
    path: "/SpecNews",
    name: "SpecNews",
    component: SpecNews,
    meta: {
      title: 'Информация о специальностях'
    }
  },
  {
    path: "/Results",
    name: "Results",
    component: Results,
    meta: {
      title: 'Результаты приема'
    }
  },
  // {
  //   path: "/PrelInfo",
  //   name: "PrelInfo",
  //   component: PrelInfo,
  //   meta: {
  //     title: 'Предварительная информация о зачислении'
  //   }
  // },
  // {
  //   path: "/Schedule",
  //   name: "Schedule",
  //   component: Schedule,
  //   meta: {
  //     title: 'График собесодований'
  //   }
  // },
  // {
  //   path: "/Rasp",
  //   name: "Rasp",
  //   component: Rasp,
  //   meta: {
  //     title: 'Расписание на 1 сентября'
  //   }
  // },
  {
    path: "/Portnoy",
    name: "Portnoy",
    component: Portnoy,
    meta: {
      title: 'Мастер по изготовлению швейных изделий'
    }
  },
  {
    path: "/Prepodavaniye",
    name: "Prepodavaniye",
    component: Prepodavaniye,
    meta: {
      title: 'Преподавание в начальных классах'
    }
  },
  {
    path: "/InfoAboutDocDate",
    name: "InfoAboutDocDate",
    component: InfoAboutDocDate,
    meta: {
      title: 'Сроки приема документов'
    }
  },
  {
    path: "/SubmitDocuments",
    name: "SubmitDocuments",
    component: SubmitDocuments,
    meta: {
      title: 'Документы можно подать'
    }
  },
  {
    path: "/InfoAboutSubmit",
    name: "InfoAboutSubmit",
    component: InfoAboutSubmit,
    meta: {
      title: 'Подача документов для поступления'
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    return { 
      x: 0,
      y: 0,
      behavior: 'smooth',
     }
  }
});

export default router;

// ...

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  // e.g., if we have `/some/deep/nested/route` and `/some`, `/deep`, and `/nested` have titles,
  // `/nested`'s will be chosen.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title;
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title;
  }

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

// ...