<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from '../src/components/Header'
import Footer from '../src/components/Footer'
export default {
  name: 'app',
  components: {
    Header,
    Footer,
  },
}
</script>

<style lang="scss">
$bg-spk: rgb(16, 30, 56);
//Основные
a {
  color: #fff !important;
  &:hover {
    color: #fff;
  }
}
.table {
  text-align: center;
  border-color: #000;
}
.table-bordered th,
.table-bordered td {
  border-color: #000;
}
//Хедер
.dropdown button {
  color: #fff;
  &:hover {
    color: #fff;
    background-color: $bg-spk;
  }
}
.dropdown-menu {
  background-color: $bg-spk;
}
.dropdown-menu li a {
  color: #fff ;
  &:hover {
    color: rgb(16, 30, 56) !important;
    background-color: #fff;
  }
}
.b-sidebar {
  text-transform: uppercase;
  background-color: $bg-spk !important;
}
.b-sidebar .dropdown-item {
  white-space: pre-line !important;
}
.b-sidebar .nav-link {
  color: #fff !important;
}
//Слайдер
.carousel-caption {
  width: 100%;
  height: 100%;
  left: 0%;
}
.carousel-indicators li {
  background-color: rgb(95, 95, 95);
  border-radius: 100%;
  height: 20px;
  width: 20px;
}

//card
.nav-justified .nav-item {
  margin-left: 2px;
  margin-top: 2px;
  background-color: #ccc;
  text-align: center;
}
.nav-justified .nav-item .active {
  background-color: #18aec2;
  color: #fff !important;
}
.nav-justified .nav-item .nav-link {
  font-weight: 600;
  color: rgb(95, 95, 95);
}
.nav-tabs .nav-link {
  height: 100%;
}
.tab-content {
  border: 2px solid rgb(124, 124, 124);
}

//Хлебные крошки
.breadcrumb {
  background-color: #fff;
  margin-bottom: 0;
  padding-bottom: 0;
}
.breadcrumb-item a {
  color: rgb(16, 30, 56) !important;
  text-decoration: underline;
}
.breadcrumb-item.active {
  color: #000;
  font-weight: 700;
}
.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
}

//Адаптив

//Iphone 6/7/8
@media (width: 414px) and (height: 736px) {
  //Слайдер
  .carousel-caption p {
    font-size: 10px !important;
  }
}
// Экстрамалые девайсы («портретные телефоны», < 576px)
@media (max-width: 575.98px) {
  //Хедер
  .figure {
    display: none !important;
  }
  .title {
    font-size: 18px !important;
  }
  //Слайдер
  //Первый слайд
  .slide-one .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 13px;
  }
  .slide-one .carousel-caption p {
    font-size: 12px;
    margin-top: 5px !important;
  }
  //Второй слайд
  .slide-two .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 12px;
  }
  .slide-two .carousel-caption p {
    font-size: 10px !important;
    margin-top: 5px !important;
  }
  //Третий слайд
  .slide-three .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 16px;
  }
  .slide-three .carousel-caption p {
    font-size: 13px;
    margin-top: 5px !important;
  }
  //Четвертый слайд
  .slide-for .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 12px;
  }
  .slide-for .carousel-caption p {
    font-size: 13px;
    margin-top: 5px !important;
  }
  //Пятый слайд
  .slide-five .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 16px;
  }
  .slide-five .carousel-caption p {
    font-size: 13px;
    margin-top: 5px !important;
  }
  //Шестой слайд
  .slide-six .carousel-caption h1 {
    margin-top: -20px;
    font-size: 28px;
  }
  .slide-six .carousel-caption p {
    font-size: 17px;
    margin-top: 10px !important;
  }
  //Седьмой слайд
  .slide-seven .carousel-caption h1 {
    margin-top: -20px;
    font-size: 28px;
  }
  .slide-seven .carousel-caption p {
    font-size: 17px;
    margin-top: 10px !important;
  }
  //Восьмой слайд
  .slide-eight .carousel-caption h1 {
    margin-top: -20px;
    font-size: 18px;
  }
  .slide-eight .carousel-caption p {
    font-size: 17px;
    margin-top: 10px !important;
  }
  //Девятый слайд
  .slide-nine .carousel-caption h1 {
    margin-top: -20px;
    font-size: 18px;
  }
  .slide-nine .carousel-caption p {
    font-size: 17px;
    margin-top: 10px !important;
  }
  //Десятый слайд
  .slide-ten .carousel-caption h1 {
    margin-top: -20px;
    font-size: 18px;
  }
  .slide-ten .carousel-caption p {
    font-size: 17px;
    margin-top: 10px !important;
  }

  //Слайдер студентов
  .carusel-student .carousel-inner {
    height: 250px;
  }
}
@media (max-width: 515px) {
  //Хедер
  .figure {
    display: none !important;
  }
  .title {
    font-size: 14px !important;
  }
}
@media (max-width: 410px) {
  //Хедер
  .figure {
    display: none !important;
  }
  .title {
    font-size: 12px !important;
  }
}



// Малые девайсы («ландшафтные», >= 575px)
@media (min-width: 576px) and (max-width: 767.98px) {
  //Хедер
  .figure {
    display: none !important;
  }
  .title {
    font-size: 25px !important;
  }
  //Слайдер
  //Первый слайд
  .slide-one .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 23px;
  }
  .slide-one .carousel-caption p {
    font-size: 18px;
    margin-top: 10px !important;
  }
  //Второй слайд
  .slide-two .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 20px;
  }
  .slide-two .carousel-caption p {
    font-size: 15px !important;
    margin-top: 0 !important;
  }
  //Третий слайд
  .slide-three .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 20px;
  }
  .slide-three .carousel-caption p {
    font-size: 15px !important;
    margin-top: 0 !important;
  }
  //Четвертый слайд
  .slide-for .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 20px;
  }
  .slide-for .carousel-caption p {
    font-size: 15px !important;
    margin-top: 0 !important;
  }
  //Пятый слайд
  .slide-five .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 20px;
  }
  .slide-five .carousel-caption p {
    font-size: 15px !important;
    margin-top: 0 !important;
  }
  //Шестой слайд
  .slide-six .carousel-caption h1 {
    margin-top: -20px;
    font-size: 32px;
  }
  .slide-six .carousel-caption p {
    font-size: 20px;
    margin-top: 20px !important;
  }
  //Седьмой слайд
  .slide-seven .carousel-caption h1 {
    margin-top: -20px;
    font-size: 32px;
  }
  .slide-seven .carousel-caption p {
    font-size: 20px;
    margin-top: 20px !important;
  }
  //Восьмой слайд
  .slide-eight .carousel-caption h1 {
    margin-top: -20px;
    font-size: 20px;
  }
  .slide-eight .carousel-caption p {
    font-size: 20px;
    margin-top: 20px !important;
  }
  //Девятый слайд
  .slide-nine .carousel-caption h1 {
    margin-top: -20px;
    font-size: 32px;
  }
  .slide-nine .carousel-caption p {
    font-size: 20px;
    margin-top: 20px !important;
  }
  //Десятый слайд
  .slide-ten .carousel-caption h1 {
    margin-top: -20px;
    font-size: 32px;
  }
  .slide-ten .carousel-caption p {
    font-size: 20px;
    margin-top: 20px !important;
  }
}

// Средние девайсы («таблетки», >= 768px)
@media (min-width: 768px) and (max-width: 991.98px) {
  //Хедер
  .figure {
    display: none !important;
  }
  //Слайдер
  //Первый слайд
  .slide-one .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 30px;
  }
  .slide-one .carousel-caption p {
    font-size: 24px;
    margin-top: 30px !important;
  }
  //Второй слайд
  .slide-two .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 30px;
  }
  .slide-two .carousel-caption p {
    font-size: 20px !important;
    margin-top: 10px !important;
  }
  //Третий слайд
  .slide-three .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 30px;
  }
  .slide-three .carousel-caption p {
    font-size: 20px !important;
    margin-top: 10px !important;
  }
  //Четвертый слайд
  .slide-for .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 30px;
  }
  .slide-for .carousel-caption p {
    font-size: 20px !important;
    margin-top: 10px !important;
  }
  //Пятый слайд
  .slide-five .carousel-caption h1 {
    margin-top: -30px !important;
    font-size: 30px;
  }
  .slide-five .carousel-caption p {
    font-size: 20px !important;
    margin-top: 10px !important;
  }
  //Шестой слайд
  .slide-six .carousel-caption p {
    font-size: 25px;
    margin-top: 40px !important;
  }
  //Седьмой слайд
  .slide-seven .carousel-caption p {
    font-size: 25px;
    margin-top: 40px !important;
  }
  //Восьмой слайд
  .slide-eight .carousel-caption h1 {
    font-size: 32px;
  }
  .slide-eight .carousel-caption p {
    font-size: 25px;
    margin-top: 40px !important;
  }
  //Девятый слайд
  .slide-nine .carousel-caption p {
    font-size: 25px;
    margin-top: 40px !important;
  }
  //Десятый слайд
  .slide-ten .carousel-caption p {
    font-size: 25px;
    margin-top: 40px !important;
  }
  //Слайдер студентов
  .carusel-student .carousel-inner {
    height: 300px;
  }
}

// Большие девайсы (большие десктопы, >= 992px)
@media (min-width: 992px) and (max-width: 1199.98px) {
  //Слайдер
  //Первый слайд
  .slide-one .carousel-caption p {
    margin-top: 40px !important;
  }
  //Второй слайд
  .slide-two .carousel-caption p {
    margin-top: 40px !important;
  }
  //Третий слайд
  .slide-three .carousel-caption p {
    margin-top: 40px !important;
  }
  //Четвертый слайд
  .slide-for .carousel-caption p {
    margin-top: 40px !important;
  }
  //Пятый слайд
  .slide-five .carousel-caption p {
    margin-top: 40px !important;
  }
  //Шестой слайд
  .slide-six .carousel-caption p {
    margin-top: 40px !important;
  }
}


//Pixel 2
@media (width: 411px) and (height: 731px) {
  //Слайдер
  .carousel-inner h1 {
    color: red !important;
  }
}

//Iphone 5/SE
@media (width: 320px) and (height: 568px) {
  //Хедер
  .text-spk {
    font-size: 10px;
  }
  .figure {
    display: none !important;
  }
  .title {
    font-size: 15px !important;
  }
  //Слайдер
  .carousel-caption h1 {
    bottom: 130px !important;
    font-size: 10px !important;
  }
  .carousel-caption p {
    font-size: 7px !important;
    bottom: 10px !important;
  }
}

//Galaxy S5
@media (width: 360px) and (height: 640px) {
  //Слайдер
  .carousel-caption h1 {
    font-size: 10px !important;
    margin-bottom: -20px !important;
  }
  .carousel-caption p {
    font-size: 8px !important;
  }
}


</style>
