<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
h3 {
  font-size: 20px;
}
p {
  font-weight: bold;
  font-size: 19px;
}
.info {
  font-size: 22px;
  font-weight: lighter;
  color: #fff;
  padding: 8px 10px;
  text-indent: 30px;
}
.info-col {
  background-color: rgb(24, 174, 194);
}
.col-12 {
  padding-left: 0;
}
//Адаптив
@media (min-width: 576px) and (max-width: 767.98px) {
  .info {
    text-align: justify;
    padding: 15px 5px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .info {
    padding: 15px 10px;
    font-size: 17px;
  }
}
@media (max-width: 370px) {
  h1 {
    font-size: 20px;
  }
}
</style>
<template>
  <div class="Locksmith">
    <b-container>
      <b-row>
        <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
        </b-col>
        <b-col cols="12">
          <h1>
            Мастер слесарных работ
          </h1>
        </b-col>
        <b-col cols="12">
          <h3>
            Квалификация: <em>слесарь-ремонтник, слесарь механосборочных работ, слесарь-инструментальщик</em>
          </h3>
        </b-col>
        <b-col class="mb-3 info-col" cols="12" lg="6" xl="7">
          <p class="info">
            <em>
              Слесарь - квалифицированный рабочий, востребованный на любом производстве,  где есть машины и механизмы. 
              Он знает, как произвести  сборку и регулировку оборудования, приемы слесарной обработки и может 
              изготовить множество деталей . От качественной работы слесарей зависит бесперебойная работа механизмов, 
              сосредоточенных во всех отраслях экономики.
            </em>
          </p>
        </b-col>
        <b-col class="mb-3 d-none d-lg-block" cols="6" xl="5">
          <img class="img-fluid" src="../assets/img/others/locksmith.jpg" alt="Информационные системы">
        </b-col>
        <b-col cols="12">
          <p>
            Срок обучения 1 года 10 месяцев (на базе 9 классов). Форма обучения очная.
          </p>
        </b-col>
      </b-row>
      <b-row class="mb-4">
        <b-col cols="12">
          <b-tabs justified>
            <b-tab title="Необходимые навыки и знания для обучения на программе" active>
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/book.png" alt="apple">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                    Базовые знания физики и математики; <br>
                    Математическое  и логическое мышление; <br>
                    Пространственно-образное мышление; <br>
                    Расчетно-вычислительные навыки; <br>
                    Базовые навыки работы с чертежами и схемами; <br>
                    Базовые технические навыки; <br>
                    Навыки простых экспериментальных исследований.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Что предстоит изучить">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/teatcher.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Основы слесарных и сборочных работ. Материаловедение. Основы электротехники. Техническая графика. 
                      Технические измерения. Слесарная обработка деталей, изготовление, сборка и ремонт приспособлений, 
                      режущего и измерительного инструмента. Сборка, регулировка и испытание сборочных единиц, узлов 
                      и механизмов машин, оборудования, агрегатов механической, гидравлической, пневматической 
                      частей изделий машиностроения. Техническое обслуживание и ремонт узлов и механизмов 
                      оборудования, агрегатов и машин
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональная деятельность">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="2" offset="1">
                    <img class="img-fluid" src="../assets/img/others/specialist.png" alt="athom">
                  </b-col>
                  <b-col cols="12" md="8" offset-md="1">
                    <p>
                      Осуществляет монтаж и демонтаж оборудования; изготовление деталей; ремонтные работы оборудования 
                      различного назначения; проведение осмотров для выявления дефектов и определения уровня 
                      работоспособности оборудования; обеспечение исправности используемого инструментов.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Профессиональные и личные качества">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/partner.png" alt="man">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Профессиональные качества: физическая выносливость, отличное зрение и прекрасный глазомер, 
                      высокая координация, точность движений, слуховая чувствительность, хорошая память, выдержка 
                      и ответственность. <br>
                      Личные качества: высокая ответственность и дисциплина; хорошая память; стрессоустойчивость.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
            <b-tab title="Область применения полученных знаний">
              <b-container>
                <b-row class="mt-3 mb-3">
                  <b-col class="d-none d-md-block" cols="3" offset="1">
                    <img class="img-fluid" src="../assets/img/others/home.png" alt="">
                  </b-col>
                  <b-col cols="12" md="7" offset-md="1">
                    <p>
                      Компоновка, регулировка и испытание узлов и механизмов и слесарная обработка; <br>
                      Испытание собираемых узлов и механизмов на специальных установках, устранение дефектов; <br>
                      Пайка различными припоями; <br>
                      Сборка сложных машин, агрегатов и станков; <br>
                      Сборка и ремонт приспособлений, режущего и измерительного инструмента; <br>
                      Изготовление и ремонт инструмента и приспособлений разной сложности; <br>
                      Слесарная обработка деталей; <br>
                      Изготовление приспособлений различной сложности для ремонта и сборки; <br>
                      Выполнение такелажных работ при перемещении грузов с помощью простых грузоподъемных средств и 
                      механизмов, управляемых с пола; <br>
                      Проверка на точность и испытание под нагрузкой отремонтированного оборудования.
                    </p>
                  </b-col>
                </b-row>
              </b-container>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>
      <!-- <SliderStudents /> -->
    </b-container>
  </div>
</template>

<script>
import SliderStudents from "../../src/components/SliderStudents";

export default {
  name: "Locksmith",
  components: {
    SliderStudents
  },
  data() {
      return {
        items: [
          {
            text: 'Специальности',
            href: '/Specialties'
          },
          {
            text: 'Мастер слесарных работ',
            active: true
          }
        ]
      }
    }
};
</script>