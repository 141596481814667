<style lang="scss" scoped>
a.ist {
  color: rgb(30, 60, 117) !important;
  font-weight: bold;
}
p {
  font-size: 18px;
}
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
        <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>Обращение директора к абитуриенту</h1>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" class="mt-3">
        <b-img class="mr-3" src="../assets/img/news/director.jpg" fluid left width="300px"></b-img>
        <h3>Дорогой студент!</h3>
        <p class="text-justify">
          Добро пожаловать в Северский промышленный колледж – одно из ведущих учебных заведений профессионального
          образования Томской области! Каждый молодой человек после окончания школы решает куда поступать. Это непростой
          период сомнений и напряженного выбора, через который проходит каждый выпускник. Очень важно, взвесив все «за»
          и «против», сделать верный шаг. Убежден, мы живем в интересное время, когда молодежи открыты все пути! Надо
          только уметь видеть перспективу, постоянно развиваться и использовать все возможности. Каждый молодой человек
          сегодня хочет не только заниматься любимым делом, но и получать достойную заработную плату, иметь карьерный
          рост. Северский промышленный колледж (СПК) готов помочь в достижении этих целей!
        </p>
        <p>Итак, пять причин выбрать Северский промышленный колледж.</p>
        <p class="text-justify">
          <b>Во-первых</b>, СПК идет в ногу со временем, постоянно развивается, дает возможность получить качественное
          профессиональное образование по широкому спектру востребованных на рынке труда направлений, а также смежную
          профессию. У нас внедряются современные программы ТОП-50 (
          <a class="ist" href="http://rosmintrud.ru/docs/mintrud/orders/436" target="_blank" rel="noopener noreferrer"
            >https://rosmintrud.ru/docs/mintrud/orders/436</a
          >), мы участвуем в региональных проектах нацпроекта «Образование» - «Молодые профессионалы», «Цифровая
          образовательная среда». В колледже открываются новые лаборатории и классы, аудитории нашего учебного заведения
          оснащены современным оборудованием. Студенты учатся в комфортных условиях небольшого города Северска.
          Например, какое направление одно их самых востребованных? Конечно, информационные технологии. Сегодня хороший
          «айтишник» сможет работать в любой сфере: медицине, строительстве, промышленности, сфере услуг. У нас создан и
          успешно работает Центр развития компетенций в области информационных технологий, нацеленный на опережающую
          подготовку и переподготовку специалистов для развития ЗАТО Северск и IT-компаний Томской области в
          соответствии с мировыми стандартами. Он включает пять оснащенных современным оборудованием IT-лабораторий:
          «Веб-дизайн и разработка», «Программные решения для бизнеса», «Разработка компьютерных игр и мультимедийных
          приложений», «IT-решения для бизнеса на платформе «1С: Предприятие 8», «Разработка мобильных приложений».
        </p>
        <p>          
          <a class="ist" href="/Specialties" rel="noopener noreferrer"
            >Также мы готовим электриков, механиков, юристов, воспитателей и др.</a
          >
        </p>
        <p>
          <b>Во-вторых</b>, как отклик на запросы времени, у нас создана и развивается внутренняя система «Электронный
          колледж», включающая: Moodle и систему веб-конференций; единую систему аутентификации; собственный
          корпоративный мессенджер, пока работающий в тестовом режиме; удаленный помощник; единую корпоративную почтовую
          службу. Ближайшие перспективы - это развитие облачных технологий, переход в веб-пространство, внедрение
          искусственного интеллекта во все процессы.
        </p>
        <p>
          <b>В-третьих</b>, поступив в СПК, можно получить перспективную специальность, знания и бесценный опыт! Наши
          студенты участвуют в олимпиадном движении «Молодые профессионалы» WorldSkills Russia (<a
            class="ist"
            href="https://worldskills.ru/"
            target="_blank"
            rel="noopener noreferrer"
            >https://worldskills.ru/</a
          >),конкурсах профессионального мастерства и уже во время обучения получают шанс не только проверить свои
          знания, умения, но и быть замеченными работодателем. Они проходят практику и трудоустраиваются на предприятиях
          города и области: АО «СХК», АО ОДЦ «УГР», ОАО «Востокгазпром», ООО «Томскнефтехим», ОАО «Городские
          электрические сети», ОАО «Северский водоканал», ГК «ЛАМА», КФ «Антонов двор», фирма «ГеоСпейс» и других.
        </p>
        <p>
          <b>В-четвертых</b>, старания и стремления студентов получают достойное вознаграждение. Самые успешные ребята,
          которые хорошо учатся и ведут активную общественную жизнь, получают дополнительные именные стипендии
          Правительства РФ, Городского округа ЗАТО Северск, АО «СХК», Российского профсоюза работников атомной
          энергетики и промышленности. Кроме того, принимают участие и побеждают в конкурсах: на звание «Лауреат премии
          Томской области в сфере образования, науки, здравоохранения и культуры» (<a
            class="ist"
            href="https://www.tomsk.gov.ru/contests/front/view/id/226"
            target="_blank"
            rel="noopener noreferrer"
            >https://www.tomsk.gov.ru/contests/front/view/id/226</a
          >),на соискание премии Законодательной Думы Томской области в номинации «Юное дарование» (<a
            class="ist"
            href="https://duma.tomsk.ru/content/premija_zakonodatelnoj_dumy_tomskoj_oblasti_dlja_molodykh_uchenykh_i_junykh_darovanij"
            target="_blank"
            rel="noopener noreferrer"
            >https://duma.tomsk.ru/content/premija_zakonodatelnoj_dumy_tomskoj_oblasti_dlja_molodykh_uchenykh_i_junykh_darovanij</a
          >).
        </p>
        <p>
          <b>В-пятых</b>, в колледже созданы условия для того, чтобы ты стал более уверенным, открытым и
          коммуникабельным, умел выступать перед аудиторией и самостоятельно принимать ответственные решения. Эти
          качества помогут в жизни, трудовом коллективе, в семье. Поверь, здесь тебя ждет яркая, насыщенная,
          незабываемая студенческая жизнь и новые друзья! У нас созданы и успешно работают: студенческий совет,
          военно-спортивный патриотический клуб «Долг», волонтерская организация, спортивные секции и кружки по
          интересам. Ребята принимают участие в различных творческих конкурсах, спортивных соревнованиях, молодежных
          форумах, волонтерских мастер-классах не только в Северске, но и в Томске. Какие бы ни были твои интересы, у
          нас сможешь попробовать свои силы в любом деле!
        </p>
      </b-col>
      <b-col cols="9"> </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  data() {
    return {
      items: [
        {
          text: 'Главная',
          href: '/',
        },
        {
          text: 'Обращение директора к абитуриенту',
          actrive: true,
        },
      ],
    }
  },
}
</script>