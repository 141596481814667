<style lang="scss" scoped>
//Основные стили
p {
  color: #000;
  text-align: justify;
  font-weight: 700;
  font-size: 20px;
  line-height: 23px;
}
.name {
  font-size: 25px;
  color: rgb(24, 174, 194);
  text-indent: 30px;
}
img {
  width: 200px;
}
//Адаптив
@media (max-width: 575.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 12px !important;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .name {
    text-indent: 0;
  }
  .carousel-caption p {
    font-size: 20px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .carousel-caption p {
    font-size: 15px;
  }
}
</style>
<template>
  <b-carousel
    id="carousel-1"
    :interval="10000"
    indicators
    img-width="1024"
    img-height="280"
    class="carusel-student mb-3 pb-5"
  >
    <b-carousel-slide img-blank img-alt="Blank image">
      <b-container>
        <b-row>
          <b-col cols="8" offset="3">
            <p class="name mb-0 mb-lg-2">Вероника, выпускница 2018 года.</p>
          </b-col>
          <b-col class="d-none d-lg-block" cols="3">
            <img class="img-fluid" src="../assets/img/others/samoilova.png" alt="student" />
          </b-col>
          <b-col cols="12" lg="8">
            <p>
              Больше всего учёба запомнилась подготовкой и участием в чемпионате «Молодые профессионалы» WorldSkills, на
              областном уровне я заняла 2-е место. Впоследствии это помогло мне трудоустроиться: на муниципальном этапе
              конкурса меня заметила заведующая нашим садом и предложила работу. Я была рада прийти учиться в СПК! Здесь
              я встретила замечательных педагогов, дружную группу, получила новые знакомства и знания. <br />
              В настоящее время - воспитатель МБДОУ «ЦРР - детский сад № 60»
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-carousel-slide>
  </b-carousel>
</template>
<script>
export default {}
</script>
