<style lang="scss" scoped>
h1 {
  color: rgb(24, 174, 194);
  margin: 15px 0;
}
a {
  color: rgb(16, 30, 56) !important;
  text-decoration: underline;
}

</style>
<template>
  <b-container>
    <b-row>
      <b-col cols="12">
          <b-breadcrumb :items="items"></b-breadcrumb>
      </b-col>
      <b-col cols="12">
        <h1>
          График собеседований
        </h1>
      </b-col>
      <p>График собеседований для студентов, зачисленных на первый курс (на бюджет), 112 аудитория</p>
      <b-col cols="12">
        <b-table-simple small bordered responsive>
          <colgroup>
            <col span="1">
            <col span="1">
            <col span="1">
          </colgroup>
          <colgroup>
            <col span="1" style=" width: 100px">
          </colgroup>
          <b-tbody>
            <b-tr>
              <b-th colspan="1">Группа</b-th>
              <b-th colspan="1">Дата</b-th>
              <b-th colspan="1">Время проведения</b-th>
            </b-tr>
            <b-tr>
              <b-td>
                <b>Д 191</b><br>«Информационные системы и программирование»<br>(9 кл.)
              </b-td>
              <b-td>
                24.08.2021<br>вторник
              </b-td>
              <b-td>10-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>Д 191/1</b><br>«Информационные системы и программирование»<br>(9 кл.)
              </b-td>
              <b-td>
                24.08.2021<br>вторник
              </b-td>
              <b-td>14-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>Д021</b><br>«Электрические станции, сети и системы»
              </b-td>
              <b-td>
                25.08.2021<br>среда
              </b-td>
              <b-td>10-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>Д041</b><br>Монтаж, техническое обслуживание и ремонт промышленного оборудования
              </b-td>
              <b-td>
                25.08.2021<br>среда
              </b-td>
              <b-td>14-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>Д111</b><br>«Дошкольное образование»
              </b-td>
              <b-td>
                26.08.2021<br>четверг
              </b-td>
              <b-td>10-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>Д051</b><br>«Тепловые электрические станции»
              </b-td>
              <b-td>
                26.08.2021<br>четверг
              </b-td>
              <b-td>14-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>Д191/2</b><br>«Информационные системы и программирование»<br>(11кл.)
              </b-td>
              <b-td>
                27.08.2021<br>пятница
              </b-td>
              <b-td>14-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>ДН021</b><br>Электромонтер по ремонту и обслуживанию электрооборудования
              </b-td>
              <b-td>
                30.08.2021<br>понедельник
              </b-td>
              <b-td>14-00 час.</b-td>              
            </b-tr>
            <b-tr>
              <b-td>
                <b>ДН041</b><br>«Мастер слесарных работ»
              </b-td>
              <b-td>
                31.08.2021<br>понедельник
              </b-td>
              <b-td>10-00 час.</b-td>              
            </b-tr>           
          </b-tbody>
        </b-table-simple>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
export default {
  name: "Schedule",
  data() {
      return {
        items: [
          {
            text: 'График собесодований',
            href: '/Schedule'
          }
        ]
      }
    }
}
</script>
